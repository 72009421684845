import { S3Client, GetObjectCommand, PutObjectCommand, ListObjectsV2Command, PutObjectCommandInput } from "@aws-sdk/client-s3";
import { Document, DocumentMetadata, ContentData } from "./Common";
import {Content} from "./editor/Content"

export class AWSLibrary {
    public s3Client: S3Client;

    constructor() {
        this.s3Client = new S3Client({
            region: process.env.REACT_APP_REGION!, 
            credentials: {
              accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID!, 
              secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY!
            }
        });
    }

    async createDocument(userEmail: string, fileName: string): Promise<any | null> {
        const putObjectParams: PutObjectCommandInput = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME!,
            Key: `${userEmail}/${fileName}.json`,
            Body: JSON.stringify(new Content().serialize()),
            ContentType: "application/json",
        };
    
        try {
            await this.s3Client.send(new PutObjectCommand(putObjectParams));
            return null;
        } catch(e) {
            return e;
        }
    }

    async saveDocument(contentData: ContentData, userEmail: string, fileName: string): Promise<any | null> {
        const putObjectParams: PutObjectCommandInput = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME!,
            Key: `${userEmail}/${fileName}.json`,
            Body: JSON.stringify(contentData),
            ContentType: "application/json",
        };

        try {
            await this.s3Client.send(new PutObjectCommand(putObjectParams));
            return null;
        } catch(e) {
            return e;
        }
    }

    async getDocument(documentName: string): Promise<[Document | null, any]> {
        const getObjectParams = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME!,
            Key: documentName,
            ResponseCacheControl: "no-cache",
        };

        try {
            const res = await this.s3Client.send(new GetObjectCommand(getObjectParams))
            const bodyString = await res.Body?.transformToString();

            const document: Document = {
                documentMetadata: {
                    title: documentName,
                    lastModified: res.LastModified,
                    owner: "You"
                },
                content: bodyString
            }

            return [document, null];
        } catch(e: any) {
            return [null, e];
        }
    }

    async getDocumentsForUser(userEmail: string): Promise<[DocumentMetadata[] | null, any]> {
        const listObjectsParams = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME!,
          Prefix: `${userEmail}/`,
        };
    
        try {
            const res = await this.s3Client.send(new ListObjectsV2Command(listObjectsParams))
            const files = res.Contents;
            if (files == undefined) {
                return [[], null];
            }

            const documentMetadatas: DocumentMetadata[] = [];
            for (let file of files) {
                documentMetadatas.push({
                    title: file.Key,
                    lastModified: file.LastModified,
                    owner: "You",
                })
            }
            return [documentMetadatas, null];
        } catch(e: any) {
            return [null, e];
        }
    }
}