import { Content } from "./Content"
import { Paragraph } from "./Paragraph"
import { LatexBlock, LatexStyle } from "./LatexBlock";

interface Comparable<T> {
    equals(other: T): boolean;
    lessThan(other: T): boolean;
    greaterThan(other: T): boolean;	
}

export class CursorPosition implements Comparable<CursorPosition> {
	private paragraphNumber: number;
	private blockNumber: number;
	private offset: number;

	constructor(paragraphNumber: number, blockNumber: number, offset: number) {
		this.paragraphNumber = paragraphNumber;
		this.blockNumber = blockNumber;
		this.offset = offset;
	}

	public increment(content: Content) {
		// Invariant: the maxium the offset can be is the length of the block. The minimum is 0.
		// Convention: when the offset is the length of the block, and we increment the cursorPosition,
		// we increment the block number and set the offset to 1, not 0.

		let blockLength = content.at(this.paragraphNumber).at(this.blockNumber).length();
		if (this.offset < blockLength) {
			this.offset++;
		} else if (this.blockNumber < content.at(this.paragraphNumber).getBlocks().length - 1) {
			this.blockNumber++;
			this.offset = 1;

			// Edge case where the block has length 0
			if (content.at(this.paragraphNumber).at(this.blockNumber).length() == 0) {
				this.offset = 0;
			}
		} else if (this.paragraphNumber < content.getParagraphs().length - 1) {
			this.paragraphNumber++;
			this.blockNumber = 0;
			this.offset = 1;

			// Edge case where the block has length 0
			if (content.at(this.paragraphNumber).at(this.blockNumber).length() == 0) {
				this.offset = 0;
			}
		}
	}

	public decrement(content: Content) {
		// Invariant: the maxium the offset can be is the length of the block. The minimum is 0.
		// Convention: when the offset is 0, and we decrement the cursorPosition, we decrement the
		// block number and set the offset to the l - 1, not l, where l is the length of the
		// previous block.

		if (this.offset > 0) {
			this.offset--;
		} else if (this.blockNumber > 0) {
			this.blockNumber--;
			this.offset = content.at(this.paragraphNumber).at(this.blockNumber).length() - 1;

			// Edge case where block has length 0
			if (content.at(this.paragraphNumber).at(this.blockNumber).length() == 0) {
				this.offset = 0;
			}
		} else if (this.paragraphNumber > 0) {
			this.paragraphNumber--;
			this.blockNumber = content.at(this.paragraphNumber).getBlocks().length - 1;
			this.offset = content.at(this.paragraphNumber).at(this.blockNumber).length() - 1;

			// Edge case where block has length 0
			if (content.at(this.paragraphNumber).at(this.blockNumber).length() == 0) {
				this.offset = 0;
			}
		}
	}

	public incrementText(content: Content, text: string) {
		for (let i = 0; i < text.length; i++) {
			this.increment(content);
		}
	}

	public decrementText(content: Content, text: string) {
		for (let i = 0; i < text.length; i++) {
			this.decrement(content);
		}
	}

	public naiveIncrement(content: Content) {
		let blockLength: number = content.at(this.paragraphNumber).at(this.blockNumber).length();
		if (this.offset < blockLength) {
			this.offset++;
		} else if (this.blockNumber < content.at(this.paragraphNumber).getBlocks().length - 1) {
			this.blockNumber++;
			this.offset = 0;
		} else if (this.paragraphNumber < content.getParagraphs().length - 1) {
			this.paragraphNumber++;
			this.blockNumber = 0;
			this.offset = 0;
		}
	}

	public naiveDecrement(content: Content) {
		if (this.offset > 0) {
			this.offset--;
		} else if (this.blockNumber > 0) {
			this.blockNumber--;
			this.offset = content.at(this.paragraphNumber).at(this.blockNumber).length();
		} else if (this.paragraphNumber > 0) {
			this.paragraphNumber--;
			this.blockNumber = content.at(this.paragraphNumber).getBlocks().length - 1;
			this.offset = content.at(this.paragraphNumber).at(this.blockNumber).length();
		}
	}

	public getBlockCoordinates(blockMap: Map<String, Array<Array<Number>>>): [number, number, number, number] {
		let coordinates = blockMap.get(`${this.paragraphNumber}-${this.blockNumber}`)!;

		for (let i = 0; i < coordinates.length - 1; i++) {
			let upperOffset = coordinates[i + 1][3] as number;
			if (this.offset <= upperOffset) {
				return coordinates[i] as [number, number, number, number];
			}
		}

		return coordinates[coordinates.length - 1] as [number, number, number, number];
	}

	// This method updates this cursor position when content is purified.
	// Takes in index, the index of the paragraph that will be deleted as part of purification.
	// Index must be valid.
	public purifyContent(content: Content, index: number) {
		if (this.paragraphNumber == index) {
			if (this.paragraphNumber == 0) {
				this.paragraphNumber = 0;
				this.blockNumber = 0;
				this.offset = 0;
			} else {
				this.paragraphNumber--;
				this.blockNumber = content.at(this.paragraphNumber).getBlocks().length - 1;
				this.offset = content.at(this.paragraphNumber).at(this.blockNumber).length();
			}
		} else if (this.paragraphNumber > index) {
			this.paragraphNumber--;
		}
	}

	// This method updates this cursor position when a paragraph is purified.
	// Takes in paragraphIndex, the index of the paragraph that is being purified,
	// and blockIndex, the index of the block that will be deleted as part of purification.
	// Both indicies must be valid.
	public purifyParagraph(paragraph: Paragraph, paragraphIndex: number, blockIndex: number) {
		if (this.paragraphNumber == paragraphIndex) {
			if (this.blockNumber == blockIndex) {
				if (this.blockNumber == 0) {
					this.blockNumber = 0;
					this.offset = 0;
				} else {
					this.blockNumber--;
					this.offset = paragraph.at(blockIndex - 1).length();
				}
			} else if (this.blockNumber > blockIndex) {
				this.blockNumber--;
			}
		}
	}


	// This method updates this cursor position when the given content is coalesced.
	// Takes in the content, the index of the parent paragraph (which will be expanded)
	// and the index of the child paragraph (which will be deleted) as part of coalescing.
	// The parentIndex must be equal to the childIndex - 1, and must be a valid index.
	public coalesceContent(content: Content, parentIndex: number, childIndex: number) {
		if (this.paragraphNumber == childIndex) {
			this.paragraphNumber--;
			let parentLength = content.at(parentIndex).getBlocks().length;
			this.blockNumber += parentLength;
		} else if (this.paragraphNumber > childIndex) {
			this.paragraphNumber--;
		}
	}

	// This method updates this cursor position when the given paragraph is coalesced.
	// Takes in the paragraph, the index of the parent block (which will be expanded)
	// and the index of the child block (which will be deleted) as part of coalescing.
	// The parentIndex must be equal to the childIndex - 1, and must be a valid index.
	public coalesceParagraph(paragraph: Paragraph, paragraphIndex: number, parentIndex: number, childIndex: number) {
		if (paragraphIndex == this.paragraphNumber) {
			if (this.blockNumber == childIndex) {
				this.blockNumber--;
				let parentLength = paragraph.at(parentIndex).length();
				this.offset += parentLength;
			} else if (this.blockNumber > childIndex) {
				this.blockNumber--;
			}
		}
	}

	public inDynamicLatex(content: Content): boolean {
		let block = content.at(this.paragraphNumber).at(this.blockNumber);
		return block instanceof LatexBlock && block.getStyle(LatexStyle.DYNAMIC);
	}

	public isValid(content: Content): boolean {
		return this.paragraphNumber >= 0 && 
			this.blockNumber >= 0 && this.offset >= 0 &&
			this.paragraphNumber < content.getParagraphs().length &&
			this.blockNumber < content.at(this.paragraphNumber).getBlocks().length &&
			this.offset <= content.at(this.paragraphNumber).at(this.blockNumber).length();
	}

	public atBeginning(): boolean {
		return this.paragraphNumber == 0 && this.blockNumber == 0 && this.offset == 0;
	}

	public atBeginningOfParagraph(): boolean {
		return this.blockNumber == 0 && this.offset == 0;
	}

	public atEnd(content: Content): boolean {
		let lastParagraphNumber = content.getParagraphs().length - 1;
		let lastBlockNumber = content.at(lastParagraphNumber).getBlocks().length - 1;
		let lastOffset = content.at(lastParagraphNumber).at(lastBlockNumber).length();

		return this.paragraphNumber == lastParagraphNumber && this.blockNumber == lastBlockNumber && this.offset == lastOffset;
	}

	public atEndOfParagraph(content: Content): boolean {
		let currentParagraph = content.at(this.paragraphNumber);
		return this.blockNumber == currentParagraph.getBlocks().length - 1 &&
			this.offset == currentParagraph.at(currentParagraph.getBlocks().length - 1).length();
	}

	public copy(other: CursorPosition) {
		this.paragraphNumber = other.paragraphNumber;
		this.blockNumber = other.blockNumber;
		this.offset = other.offset;
	}

	public static duplicate(other: CursorPosition): CursorPosition {
		return new CursorPosition(other.paragraphNumber, other.blockNumber, other.offset)
	}

	public static min(cursorPosition1: CursorPosition, cursorPosition2: CursorPosition): CursorPosition {
		if (cursorPosition1.lessThan(cursorPosition2)) {
			return cursorPosition1;
		}
		return cursorPosition2;
	}

	public static max(cursorPosition1: CursorPosition, cursorPosition2: CursorPosition): CursorPosition {
		if (cursorPosition1.greaterThan(cursorPosition2)) {
			return cursorPosition1;
		}
		return cursorPosition2;
	}

	public equals(other: CursorPosition): boolean {
		if (other == null) {
			return false;
		}
		return this.paragraphNumber == other.paragraphNumber && this.blockNumber == other.blockNumber && this.offset == other.offset;
	}

	public lessThan(other: CursorPosition): boolean {
		if (this.paragraphNumber < other.paragraphNumber) {
			return true;
		}

		if (this.paragraphNumber > other.paragraphNumber) {
			return false;
		}

		if (this.blockNumber < other.blockNumber) {
			return true;
		}

		if (this.blockNumber > other.blockNumber) {
			return false;
		}

		if (this.offset < other.offset) {
			return true;
		}

		return false;
	}

	public greaterThan(other: CursorPosition): boolean {
		if (this.paragraphNumber > other.paragraphNumber) {
			return true;
		}

		if (this.paragraphNumber < other.paragraphNumber) {
			return false;
		}

		if (this.blockNumber > other.blockNumber) {
			return true;
		}

		if (this.blockNumber < other.blockNumber) {
			return false;
		}

		if (this.offset > other.offset) {
			return true;
		}

		return false;
	}

	public lessThanOrEqualTo(other: CursorPosition): boolean {
		return this.lessThan(other) || this.equals(other);
	}

	public greaterThanOrEqualTo(other: CursorPosition): boolean {
		return this.greaterThan(other) || this.equals(other);
	}

	public setParagraphNumber(paragraphNumber: number) {
		this.paragraphNumber = paragraphNumber;
	}

	public setBlockNumber(blockNumber: number) {
		this.blockNumber = blockNumber;
	}

	public setOffset(offset: number) {
		this.offset = offset;
	}

	public getParagraphNumber(): number {
		return this.paragraphNumber;
	}

	public getBlockNumber(): number {
		return this.blockNumber;
	}

	public getOffset(): number {
		return this.offset;
	}

	public toString() {
		return "ParagraphNumber: " + this.paragraphNumber + ", BlockNumber: " + this.blockNumber + ", Offset: " + this.offset;
	}
}
