import { Content } from "./Content";
import { TextParagraph } from "./TextParagraph";
import { LatexParagraph } from "./LatexParagraph";
import { Block } from "./Block"
import { Cursor } from "./Cursor";
import { BlockDataType, LatexBlockData } from "../Common"
import katex from "katex";

export enum LatexStyle {
    DYNAMIC = "dynamic",
    VANILLA = "vanilla",
}

export class LatexBlock extends Block {
    private style: LatexStyle;

    constructor(text: string = "", style: LatexStyle = LatexStyle.VANILLA) {
        super(text);
        this.style = style;
    }

    public styleEquals(other: Block): boolean {
        if (!(other instanceof LatexBlock)) {
            return false;
        }

        return this.style == other.style;
    }

    public renderChunk(content: Content, cursor: Cursor, pn: number, bn: number, _startOffset: number, _chunkSize: number): [HTMLElement, number, boolean] {
        return [this.render(content, cursor, pn, bn), this.text.length, true];
    }

    public render(content: Content, cursor: Cursor, pn: number, bn: number): HTMLElement {
        let div = document.createElement("div");
        div.classList.add("block");
        div.classList.add("latexblock");
        div.classList.add(this.style);
        div.setAttribute('content-structure', `${pn}-${bn}`);

        if (this.renderKatex(content, cursor, pn, bn)) {
            div.innerHTML = katex.renderToString(this.text, {throwOnError: false});
        } else {
            div.innerHTML = this.text;
            div.classList.add("code");
        }
        return div;
    }

    private renderKatex(content: Content, cursor: Cursor, pn: number, bn: number): boolean {
        let cursorInsideBlock = cursor.insideBlock(pn, bn);
        let cursorInsideParagraph = cursor.insideParagraph(pn);
        let textParagraph = content.at(pn) instanceof TextParagraph;
        let latexParagraph = content.at(pn) instanceof LatexParagraph;
        let vanilla = this.style == LatexStyle.VANILLA;
        let dynamic = this.style == LatexStyle.DYNAMIC;

        let renderKatexForLatexParagraph = latexParagraph && !cursorInsideParagraph;
        let renderKatexForTextParagraph = textParagraph && (!(vanilla && cursorInsideBlock) || dynamic);

        return renderKatexForLatexParagraph || renderKatexForTextParagraph;
    }
   
    /* Getters */

    public getStyle(style: LatexStyle | null): boolean {
        if (style == null) {
            return false;
        }
        return this.style == style;
    }

    public getAppliedStyle(): LatexStyle {
        return this.style;
    }

    /* Serialization methods for state and saving to/getting from cloud */

    public serialize(): LatexBlockData {
        let textBlockData: LatexBlockData = {
            type: BlockDataType.LATEX,
            text: this.text,
            style: this.style
        }

        return textBlockData;
    }

    public deSerialize(data: LatexBlockData) {
        this.text = data.text;
        this.style = data.style as unknown as LatexStyle;
    }
}
