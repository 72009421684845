import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    VStack,
    useColorModeValue,
} from "@chakra-ui/react";
import { MiniEditor } from "../mini-editor/MiniEditor";
import { LatexCode } from "./LatexCode";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useState } from "react";
import { colors } from "../theme/theme";
import { useNavigate } from "react-router-dom";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionButton = motion(Button);

export const IntroSection = () => {
    const actionButtonColors = colors.landingPage.actionButton;
    const controls = useAnimation();
    const [oldWay, setOldWay] = useState(true);
    const [startAnimation, setStartAnimation] = useState(false);
    const navigate = useNavigate();
    const codeBlockBg = useColorModeValue(
        colors.landingPage.light.codeBg,
        colors.landingPage.dark.codeBg
    );

    const handleClick = () => {
        setStartAnimation(true);

        setTimeout(() => {
            setOldWay(false);
        }, 550);

        setTimeout(() => {
            controls
                .start({
                    boxShadow: [
                        "0px 0px 10px 5px white",
                        "0px 0px 20px 10px white",
                    ],
                    transition: {
                        duration: 1.1,
                        repeat: 20,
                        repeatType: "mirror",
                        ease: "easeInOut",
                    },
                })
                .then(() => {
                    controls.start({
                        boxShadow: [
                            "0px 0px 20px 10px white",
                            "0px 0px 15px 8px white",
                        ],
                        transition: {
                            duration: 2,
                            ease: "easeInOut",
                        },
                    });
                });
        }, 600);
    };

    return (
        <Flex
            as="main"
            flex={1}
            align="center"
            justify="center"
            p={8}
            position="relative"
            zIndex={1}
        >
            <MotionFlex
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="space-between"
                maxW="container.xl"
                animate={{ width: oldWay ? "75%" : "100%" }}
                transition={{ duration: 0.6 }} // Smooth width transition
                mx="auto"
            >
                <VStack
                    align="flex-start"
                    spacing={8}
                    maxW={{ base: "full", md: "50%" }}
                    mb={{ base: 12, md: 0 }}
                >
                    <Heading
                        color="white"
                        as="h2"
                        size="4xl"
                        lineHeight="tight"
                        fontWeight="extraBold"
                    >
                        Write Beautiful
                        <Box display="block">
                            LaTeX
                            <Text as="span" color={colors.accentYellow}>
                                {" "}
                                Fast.
                            </Text>
                        </Box>
                    </Heading>
                    <Text fontSize="xl" color="gray.200" maxW="md">
                        Streamline your LaTeX workflow with Parchmynt's
                        intuitive editor.
                    </Text>
                    {oldWay ? (
                        <MotionButton
                            onClick={handleClick}
                            size="lg"
                            bg={actionButtonColors.showMeHowYellow}
                            color={actionButtonColors.showMeHowButtonTextColor}
                            fontWeight="semibold"
                            rounded="full"
                            px={8}
                            style={{
                                backgroundColor:
                                    actionButtonColors.showMeHowYellow,
                            }}
                            whileHover={{
                                scale: 1.1,
                                transition: {
                                    duration: 0.1,
                                    ease: "easeInOut",
                                },
                            }}
                            boxShadow="lg"
                        >
                            Show Me How
                        </MotionButton>
                    ) : (
                        <MotionButton
                            onClick={() => navigate("/signup")}
                            size="lg"
                            bg={actionButtonColors.signUpBlue}
                            color={actionButtonColors.signUpButtonTextColor}
                            fontWeight="semibold"
                            rounded="full"
                            px={8}
                            style={{
                                backgroundColor: actionButtonColors.signUpBlue,
                            }}
                            whileHover={{
                                scale: 1.1,
                                transition: {
                                    duration: 0.1,
                                    ease: "easeInOut",
                                },
                            }}
                            animate={controls}
                            boxShadow="lg"
                        >
                            Sign Up Today
                        </MotionButton>
                    )}
                </VStack>

                <Box maxW={{ base: "full", md: "50%" }} w="100%">
                    <AnimatePresence exitBeforeEnter>
                        {oldWay ? (
                            <MotionBox
                                bg={codeBlockBg}
                                backdropFilter="blur(10px)"
                                rounded="lg"
                                p={6}
                                boxShadow="xl"
                                key="latex"
                                initial={{ rotateY: 0 }}
                                animate={{ rotateY: startAnimation ? 90 : 0 }}
                                transition={{
                                    duration: 0.6,
                                    animationDirection: "normal",
                                }}
                                w="600px"
                            >
                                <LatexCode />
                            </MotionBox>
                        ) : (
                            <MotionBox
                                bg={codeBlockBg}
                                backdropFilter="blur(10px)"
                                rounded="lg"
                                p={6}
                                boxShadow="xl"
                                key="editor"
                                initial={{ rotateY: 90 }}
                                animate={{ rotateY: 0 }}
                                transition={{
                                    duration: 0.6,
                                    animationDirection: "normal",
                                }}
                            >
                                <MiniEditor />
                            </MotionBox>
                        )}
                    </AnimatePresence>
                </Box>
            </MotionFlex>
        </Flex>
    );
};
