import {
    useColorMode,
    useColorModeValue,
    IconButton,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import { ColorModeSwitcherLocation } from "../Common";
import { colors } from "../theme/theme";

interface ColorModeSwitcherProps {
    location: ColorModeSwitcherLocation;
}

export const ColorModeSwitcher = ({ location }: ColorModeSwitcherProps) => {
    const { toggleColorMode } = useColorMode();
    const text = useColorModeValue("dark", "light");
    const SwitchIcon = useColorModeValue(FaMoon, FaSun);

    return (
        <IconButton
            fontSize="2xl"
            variant="ghost"
            color={
                location == ColorModeSwitcherLocation.EDITOR ? text: "white"
            }
            padding={2}
            onClick={toggleColorMode}
            icon={<SwitchIcon />}
            aria-label={`Switch to ${text} mode`}
            _hover={{ color: colors.navbarHoverYellow }}
        />
    );
};
