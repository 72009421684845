import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { colors } from "../theme/theme";

const latexSnippet = `\\begin{document}

\\title{My Old Latex Document}
\\author{Frustrated Author}
\\date{\\yesterday}

\\maketitle

\\begin{question}
    Name two cool things about $e^x$. \\\\

    1) $e^x = \\sum_{n=0}^{\\infty} \\frac{x^n}{n!}$ \\\\
    2) $e^x = \\int e^x \\ dx$

\\end{question}

\\end{document}`;

export const LatexCode = () => {
    const highlighedLatexColor = useColorModeValue(colors.primaryPurple, colors.darkPurple);
    const speed = 25;
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
        let index = 0;
        const intervalId = setInterval(() => {
            if (index <= latexSnippet.length) {
                setDisplayedText(latexSnippet.substring(0, index));
                index++;
            } else {
                clearInterval(intervalId);
            }
        }, speed);

        return () => clearInterval(intervalId);
    }, []);

    const keywords = ["Old", "Frustrated"];
    let remainingText = displayedText;

    let highlightedElements = [];

    for (let i = 0; i < keywords.length; i++) {
        let keywordIndex = remainingText.indexOf(keywords[i]);

        if (keywordIndex != -1) {
            let beforeKeyword = remainingText.substring(0, keywordIndex);
            highlightedElements.push(beforeKeyword);

            let emphasizedKeywordElement =
                <Text
                    key={keywords[i]}
                    as="span"
                    bg={colors.accentYellow}
                    color={highlighedLatexColor}
                    fontWeight="bold"
                    padding={"3px"}
                    borderRadius={"5px"}
                >
                    {keywords[i]}
                </Text>

            highlightedElements.push(emphasizedKeywordElement);
            remainingText = remainingText.substring(keywordIndex + keywords[i].length);
        }
    }
    highlightedElements.push(remainingText);

    return (
        <Box h="400px">
            <Text
                as="pre"
                fontFamily="mono"
                fontSize={{ base: "sm", md: "md" }}
                color={colors.landingPage.latexYellow}
                whiteSpace="pre-wrap"
            >
                {highlightedElements}
            </Text>
        </Box>
    );
};
