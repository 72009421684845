import { createContext, useContext, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { RootState, AppDispatch } from "../redux/store";
import { updateData, setLoggedIn, setToken } from "../redux/slices/authSlice";
import { userPool } from "../App";
import { UserData } from "../Common";

interface AuthContextType {
    useIsLoggedIn: () => boolean;
    useGetUserData: () => UserData;
    useGetJWTToken: () => string | null;
    login: (email: string, password: string) => Promise<string | null>;
    logout: () => string;
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const useIsLoggedIn = () => {
        return useSelector((state: RootState) => {
            return state.auth.isLoggedIn;
        });
    };

    const useGetUserData = () => {
        return useSelector((state: RootState) => {
            return state.auth.userData;
        });
    };

    const useGetJWTToken = () => {
        return useSelector((state: RootState) => {
            return state.auth.token;
        });
    };

    const login = async (
        email: string,
        password: string
    ): Promise<string | null> => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        try {
            const route = await new Promise<string>((resolve, reject) => {
                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: (result) => {
                        cognitoUser.getUserAttributes(function (err, result) {
                            if (err) {
                                reject("/error");
                            }

                            dispatch(
                                updateData({
                                    firstName:
                                        result != undefined
                                            ? result[3].Value
                                            : "",
                                    lastName:
                                        result != undefined
                                            ? result[2].Value
                                            : "",
                                    email: email,
                                })
                            );
                            dispatch(setLoggedIn(true));
                            dispatch(
                                setToken(
                                    cognitoUser
                                        .getSignInUserSession()
                                        ?.getIdToken()
                                        .getJwtToken()!
                                )
                            );
                            resolve("/documents");
                        });
                    },
                    onFailure: (err) => {
                        reject(null);
                    },
                });
            });

            return route;
        } catch (error) {
            return null;
        }
    };

    const logout = (): string => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.signOut();
        }
        dispatch(
            updateData({
                firstName: "",
                lastName: "",
                email: "",
            })
        );
        dispatch(setLoggedIn(false));
        dispatch(setToken(null));
        return "/";
    };

    return (
        <AuthContext.Provider
            value={{
                useIsLoggedIn,
                useGetUserData,
                useGetJWTToken,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
