import {
    ButtonGroup,
    Flex,
    Icon,
    IconButton,
    Spacer,
    Button,
    useColorModeValue,
} from "@chakra-ui/react";
import {
    MdFormatBold,
    MdFormatItalic,
    MdFormatUnderlined,
    MdStrikethroughS,
    MdFormatClear,
} from "react-icons/md";
import { forwardRef } from "react";
import { colors } from "../theme/theme";

export const MiniToolbar = forwardRef((props: any, controllerRef: any) => {
    const iconColor = useColorModeValue("white", "white");
    const codeBlockBg = useColorModeValue(
        colors.landingPage.light.codeBg,
        colors.landingPage.dark.codeBg
    );

    const handleBold = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleBold(event);
        }
    };

    const handleItalic = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleItalic(event);
        }
    };

    const handleUnderline = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleUnderline(event);
        }
    };

    const handleStrikethrough = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleStrikethrough(event);
        }
    };

    const handleClearFormatting = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleClearFormatting(event);
        }
    };

    const handleDynamicLatex = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleDynamicLatex(event);
        }
    };

    const handleVanillaLatex = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleVanillaLatex(event);
        }
    };

    const handleLatexParagraph = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleLatexParagraph(event);
        }
    };
    return (
        <Flex
            position="sticky"
            zIndex="10"
            top="0"
            bg={codeBlockBg}
            w="100%"
            h="2vh"
            p="2rem"
            borderRadius="lg"
            justifyContent="center"
            alignItems="center"
        >
            <ButtonGroup>
                <IconButton
                    color={iconColor}
                    onClick={handleBold}
                    fontSize="3xl"
                    aria-label="bold-button"
                    variant="ghost"
                    icon={<Icon as={MdFormatBold} />}
                />
                <IconButton
                    color={iconColor}
                    onClick={handleItalic}
                    fontSize="3xl"
                    aria-label="italic-button"
                    variant="ghost"
                    icon={<Icon as={MdFormatItalic} />}
                />
                <IconButton
                    color={iconColor}
                    onClick={handleUnderline}
                    fontSize="3xl"
                    aria-label="underline-button"
                    variant="ghost"
                    icon={<Icon as={MdFormatUnderlined} />}
                />
                <IconButton
                    color={iconColor}
                    onClick={handleStrikethrough}
                    fontSize="3xl"
                    aria-label="strikethrough-button"
                    variant="ghost"
                    icon={<Icon as={MdStrikethroughS} />}
                />
                <IconButton
                    color={iconColor}
                    onClick={handleClearFormatting}
                    fontSize="3xl"
                    aria-label="clear-formatting-button"
                    variant="ghost"
                    icon={<Icon as={MdFormatClear} />}
                />
            </ButtonGroup>
            <Spacer />
            <ButtonGroup>
                <Button
                    color={iconColor}
                    onClick={handleDynamicLatex}
                    fontSize="3xl"
                    aria-label="latex-button"
                    variant="ghost"
                >
                    L
                </Button>
                <Button
                    color={iconColor}
                    onClick={handleVanillaLatex}
                    fontSize="3xl"
                    aria-label="latex-button"
                    variant="ghost"
                >
                    VL
                </Button>
                <Button
                    color={iconColor}
                    onClick={handleLatexParagraph}
                    fontSize="3xl"
                    aria-label="latex-paragraph-button"
                    variant="ghost"
                >
                    LP
                </Button>
            </ButtonGroup>
        </Flex>
    );
});
