import {
    Flex,
    Spacer,
    Text,
    Center,
    Button,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Badge,
    Box,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { ColorModeSwitcherLocation } from "../Common";
import { useAuth } from "../auth/Authentication";
import { colors } from "../theme/theme";

export const Navbar = () => {
    const { useIsLoggedIn } = useAuth();

    return (
        <Flex w="100%" padding={10} height="8vh">
            <Center>
                <Box
                    fontSize="3xl"
                    fontFamily="fonts.heading"
                    padding={10}
                    fontWeight="bold"
                    color="white"
                    zIndex={1}
                >
                    <ChakraLink
                        _hover={{ textDecoration: "none" }}
                        as={ReactRouterLink}
                        to="/"
                    >
                        Parchmynt
                        <Badge
                            colorScheme="purple"
                            px={2}
                            py={1}
                            ml={2}
                            borderRadius="xl"
                        >
                            BETA
                        </Badge>
                    </ChakraLink>
                </Box>
            </Center>
            <Spacer />
            <Center paddingRight={10}>
                <Button
                    size="lg"
                    padding={5}
                    fontSize="xl"
                    fontFamily="fonts.heading"
                    fontWeight={400}
                    color="white"
                    variant="link"
                    _hover={{ color: colors.navbarHoverYellow }}
                >
                    <ChakraLink
                        _hover={{ textDecoration: "none" }}
                        as={ReactRouterLink}
                        to="/documentation"
                    >
                        Documentation
                    </ChakraLink>
                </Button>
                <Button
                    size="lg"
                    padding={5}
                    fontSize="xl"
                    fontFamily="fonts.heading"
                    fontWeight={400}
                    color="white"
                    variant="link"
                    _hover={{ color: colors.navbarHoverYellow }}
                >
                    <ChakraLink
                        _hover={{ textDecoration: "none" }}
                        as={ReactRouterLink}
                        to="/support"
                    >
                        Support
                    </ChakraLink>
                </Button>
                {useIsLoggedIn() ? (
                    <AuthNavComponent />
                ) : (
                    <NonAuthNavComponent />
                )}
                <ColorModeSwitcher
                    location={ColorModeSwitcherLocation.NAVBAR}
                />
            </Center>
        </Flex>
    );
};

const AuthNavComponent = () => {
    const { logout, useGetUserData } = useAuth();
    const navigate = useNavigate();
    const userData = useGetUserData();

    const handleSignOut = () => {
        const route = logout();
        navigate(route);
    };

    return (
        <Flex>
            <Menu>
                <MenuButton
                    size="lg"
                    padding={5}
                    fontSize="xl"
                    fontFamily="fonts.heading"
                    fontWeight={400}
                    color="white"
                    variant="link"
                    _hover={{ color: colors.navbarHoverYellow }}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                >
                    {userData.firstName}
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    );
};

const NonAuthNavComponent = () => {
    return (
        <Flex>
            <Button
                size="lg"
                fontSize="xl"
                padding={5}
                fontFamily="fonts.heading"
                fontWeight={400}
                color="white"
                variant="link"
                _hover={{ color: colors.navbarHoverYellow }}
            >
                <ChakraLink
                    _hover={{ textDecoration: "none" }}
                    as={ReactRouterLink}
                    to="/login"
                >
                    Login
                </ChakraLink>
            </Button>
            <Button
                size="lg"
                fontSize="xl"
                padding={5}
                fontFamily="fonts.heading"
                fontWeight={400}
                color="white"
                variant="link"
                _hover={{ color: colors.navbarHoverYellow }}
            >
                <ChakraLink
                    _hover={{ color: colors.navbarHoverYellow }}
                    as={ReactRouterLink}
                    to="/signup"
                >
                    Sign Up
                </ChakraLink>
            </Button>
        </Flex>
    );
};
