import { Formik, Field, Form } from "formik";
import { useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack,
    Heading,
    Flex,
    Spacer,
    InputGroup,
    InputRightElement,
    IconButton,
    useColorModeValue,
} from "@chakra-ui/react";
import { LockIcon, UnlockIcon, WarningIcon } from "@chakra-ui/icons";
import { Footer } from "../components/Footer";
import { useAuth } from "../auth/Authentication";
import { useNavigate } from "react-router-dom";
import { AnimatedBackground } from "../components/AnimatedBackground";
import { colors } from "../theme/theme";

interface LoginProps {
    email: string;
    password: string;
}

export const LoginPage = () => {
    const bgGradient = useColorModeValue(
        `linear(to-br, ${colors.pageBackgrounds.light.startGradient}, ${colors.pageBackgrounds.light.endGradient})`,
        `linear(to-br, ${colors.pageBackgrounds.dark.startGradient}, ${colors.pageBackgrounds.dark.endGradient})`
    );

    return (
        <Flex
            bgGradient={bgGradient}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            minHeight="100vh"
            position="relative"
            overflow="hidden"
            w="100%"
        >
            <AnimatedBackground />
            <Spacer />
            <LoginForm />
            <Spacer />
            <Footer position="absolute" left="0" bottom="0" padding={3} />
        </Flex>
    );
};

const LoginForm = () => {
    const fieldTextColor = useColorModeValue("black", "white");
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (values: LoginProps) => {
        const email = values.email.trim();
        const password = values.password;
        try {
            const route = await login(email, password);
            if (route) {
                navigate(route);
            } else {
                setLoginError(true);
            }
        } catch (error) {
            setLoginError(true);
        }
    };

    return (
        <Box
            borderWidth={1}
            borderColor="whiteAlpha.400"
            p="2rem"
            rounded="xl"
            w="40%"
            color="white"
        >
            <Flex justifyContent="center" alignItems="center" pb="2rem">
                <Heading
                    fontFamily="fonts.heading"
                    fontWeight={800}
                    fontSize="3xl"
                >
                    Log In
                </Heading>
            </Flex>

            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                onSubmit={(values, actions) => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <VStack spacing={4} align="flex-start">
                            <FormControl isRequired>
                                <FormLabel htmlFor="email">
                                    Email Address
                                </FormLabel>
                                <Field
                                    as={Input}
                                    id="email"
                                    name="email"
                                    type="email"
                                    variant="ghost"
                                    placeholder="test@test.com"
                                    color={fieldTextColor}
                                />
                            </FormControl>

                            <FormControl isInvalid={loginError} isRequired>
                                <FormLabel htmlFor="password">
                                    Password
                                </FormLabel>
                                <InputGroup>
                                    <Field
                                        as={Input}
                                        id="password"
                                        name="password"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        variant="ghost"
                                        placeholder="**************"
                                        color={fieldTextColor}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label="Show password"
                                            variant="ghost"
                                            icon={
                                                showPassword ? (
                                                    <UnlockIcon />
                                                ) : (
                                                    <LockIcon />
                                                )
                                            }
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                    <WarningIcon marginRight="0.5rem" />
                                    Invalid email or password.
                                </FormErrorMessage>
                            </FormControl>

                            <Button
                                type="submit"
                                width="full"
                                id="loginButton"
                                bg={colors.accentYellow}
                                color={colors.darkPurple}
                                fontWeight="semibold"
                                rounded="full"
                                _hover={{ transform: "scale(1.05)" }}
                                transition="all 0.3s"
                                boxShadow="lg"
                            >
                                Log In
                            </Button>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
