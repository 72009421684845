import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import { Box, Button, useClipboard, useColorModeValue } from "@chakra-ui/react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import "katex/dist/katex.min.css";
import katex from "katex";

interface CodeBlockProps {
    code: string;
    text?: string;
    shouldBeRendered?: boolean;
}

export const CodeBlock: React.FC<CodeBlockProps> = ({
    code,
    text,
    shouldBeRendered = true,
}) => {
    const { onCopy, hasCopied } = useClipboard(code);
    const codeBackground = useColorModeValue("gray.100", "gray.900");

    return (
        <Box position="relative" bg={codeBackground} rounded="lg" mt={5} mb={5}>
            <Button
                position="absolute"
                top={5}
                right={5}
                size="sm"
                colorScheme="blue"
                variant="outline"
                onClick={onCopy}
                fontSize="xs"
                p={2}
                display="flex"
                alignItems="center"
                gap={1}
            >
                {hasCopied ? (
                    <CheckIcon boxSize={3} />
                ) : (
                    <CopyIcon boxSize={4} />
                )}
                {hasCopied ? "Copied" : "Copy"}
            </Button>
            <Box
                as="pre"
                overflowX="auto"
                fontFamily="monospace"
                bg="transparent"
                rounded="md"
                p={8}
            >
                <SyntaxHighlighter
                    language="latex"
                    style={{
                        ...coy,
                        'pre[class*="language-"]': {
                            background: "none",
                            padding: "0",
                            margin: "0",
                        },
                        'code[class*="language-"]': {
                            background: "none",
                            color: "inherit",
                            fontFamily: "inherit",
                            fontSize: "inherit",
                        },
                    }}
                    showLineNumbers={false}
                >
                    {code}
                </SyntaxHighlighter>
            </Box>
            <Box
                as="pre"
                overflowX="auto"
                fontFamily="monospace"
                bg="transparent"
                rounded="md"
                p={8}
                display={shouldBeRendered ? "block" : "none"}
                dangerouslySetInnerHTML={{
                    __html:
                        katex.renderToString(code) +
                        (text != undefined ? text : ""),
                }}
            />
        </Box>
    );
};
