import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { SignUpPage } from "./pages/SignUpPage";
import { DocumentationPage } from "./pages/DocumentationPage";
import { DocumentsPage } from "./pages/DocumentsPage";
import { ErrorPage } from "./pages/ErrorPage";
import { EditorPage } from "./pages/EditorPage"; // TODO: delete this later
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { AuthProvider, useAuth } from "./auth/Authentication";
import { VerifyEmailPage } from "./pages/VerifyEmailPage";
import "typeface-open-sans";
import { SupportPage } from "./pages/SupportPage";

export const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USERPOOL_ID!,
    ClientId: process.env.REACT_APP_APPCLIENT_ID!,
});

export const App = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <ChakraProvider theme={theme}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="documentation" element={<DocumentationPage />} />
                        <Route path="support" element={<SupportPage />} />
                        <Route path="login" element={<LoginPage />} />
                        <Route path="signup" element={<SignUpPage />} />
                        <Route
                            path="/documents"
                            element={
                                <PrivateRoute>
                                    <DocumentsPage />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="verifyEmail"
                            element={<VerifyEmailPage />}
                        />
                        <Route path="error" element={<ErrorPage />} />
                        <Route path="editor" element={<EditorPage />} />
                        <Route
                            path="editor/:encryptedDocumentName"
                            element={
                                <PrivateRoute>
                                    <EditorPage />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </ChakraProvider>
            </BrowserRouter>
        </AuthProvider>
    );
};

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const { useIsLoggedIn } = useAuth();
    const isPuppeteer =
        new URLSearchParams(window.location.search).get("puppeteer") === "true";

    return useIsLoggedIn() || isPuppeteer ? children : <LoginPage />;
};

const Home: React.FC = () => {
    const { useIsLoggedIn } = useAuth();

    return useIsLoggedIn() ? <DocumentsPage /> : <HomePage />;
};
