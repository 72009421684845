import { Paragraph } from "./Paragraph"
import { TextParagraph } from "./TextParagraph"
import { LatexParagraph } from "./LatexParagraph"
import { Block } from "./Block"
import { TextBlock, TextStyle } from "./TextBlock"
import { LatexBlock, LatexStyle } from "./LatexBlock"
import { Cursor } from "./Cursor"
import { CursorPosition } from "./CursorPosition"
import { ParagraphDataType, ParagraphData, ContentData } from "../Common"
import { Page } from "./Page"

export class Content {
    public static CHUNK_SIZE: number = 5000;
    private paragraphs: Paragraph[];
    private pages: Page[];

    constructor(paragraphs = new Array<Paragraph>()) {
        this.paragraphs = paragraphs;
        this.pages = [];
        if (this.paragraphs.length == 0) {
            this.paragraphs.push(new TextParagraph());
        }
    }

    public addParagraph(index: number, paragraph: Paragraph) {
        this.paragraphs.splice(index, 0, paragraph);
    }

    public addText(textToAdd: string, cursor: Cursor) {
        let leftEnd = CursorPosition.min(cursor.getCursorPosition(), cursor.getSelectionEnd());
        let rightEnd = CursorPosition.max(cursor.getCursorPosition(), cursor.getSelectionEnd());

        // if selection is within a single paragraph
        if (leftEnd.getParagraphNumber() == rightEnd.getParagraphNumber()) {
            let leftParagraph = this.paragraphs[leftEnd.getParagraphNumber()];
            leftParagraph.addText(textToAdd, leftEnd.getBlockNumber(), leftEnd.getOffset(), rightEnd.getBlockNumber(), rightEnd.getOffset());
        } else {
            // delete from offset in left end block -> end of left end
            // add to left end
            let leftParagraph = this.paragraphs[leftEnd.getParagraphNumber()];
            let lastLeftBlock = leftParagraph.length() - 1;
            let lastLeftOffset = leftParagraph.at(lastLeftBlock).length();
            leftParagraph.addText(textToAdd, leftEnd.getBlockNumber(), leftEnd.getOffset(), lastLeftBlock, lastLeftOffset);

            // delete from 0 in right end -> offset of right end
            let rightParagraph = this.paragraphs[rightEnd.getParagraphNumber()];
            rightParagraph.removeText(0, 0, rightEnd.getBlockNumber(), rightEnd.getOffset());

            // delete all middle paragraphs
            for (let i = leftEnd.getParagraphNumber() + 1; i < rightEnd.getParagraphNumber(); i++) {
                this.paragraphs.splice(leftEnd.getParagraphNumber() + 1, 1);
            }
        }
    }

    public removeText(cursor: Cursor) {
        let leftEnd = CursorPosition.min(cursor.getCursorPosition(), cursor.getSelectionEnd());
        let rightEnd = CursorPosition.max(cursor.getCursorPosition(), cursor.getSelectionEnd());

        // if selection is within a single paragraph
        if (leftEnd.getParagraphNumber() == rightEnd.getParagraphNumber()) {
            let leftParagraph = this.paragraphs[leftEnd.getParagraphNumber()];
            leftParagraph.removeText(leftEnd.getBlockNumber(), leftEnd.getOffset(), rightEnd.getBlockNumber(), rightEnd.getOffset());
        } else {
            // delete from offset in left end -> end of left end
            let leftParagraph = this.paragraphs[leftEnd.getParagraphNumber()];
            let lastLeftBlock = leftParagraph.length() - 1;
            let lastLeftOffset = leftParagraph.at(lastLeftBlock).length();
            leftParagraph.removeText(leftEnd.getBlockNumber(), leftEnd.getOffset(), lastLeftBlock, lastLeftOffset);

            // delete from 0 in right end -> offset of right end
            let rightParagraph = this.paragraphs[rightEnd.getParagraphNumber()];
            rightParagraph.removeText(0, 0, rightEnd.getBlockNumber(), rightEnd.getOffset());

            // delete all middle paragraphs
            for (let i = leftEnd.getParagraphNumber() + 1; i < rightEnd.getParagraphNumber(); i++) {
                this.paragraphs.splice(leftEnd.getParagraphNumber() + 1, 1);
            }
        }
    }

    public backspace(cursor: Cursor) {
        if (cursor.inASelection()) {
            this.removeText(cursor);
        } else if (cursor.getCursorPosition().atBeginningOfParagraph()){ // Deleting a Paragraph
            let paragraphNumber = cursor.getCursorPosition().getParagraphNumber();
            let paragraph = this.paragraphs[paragraphNumber];

            // we don't want to ever delete the first or last paragraphs
            // because we always want the first and last paragraphs to be text paragraphs
            if (paragraph.isEmpty() && paragraphNumber > 0 && paragraphNumber < this.length() - 1) {
                this.paragraphs.splice(paragraphNumber, 1);
            }
        } else {
            let cursorPosition = cursor.getCursorPosition();
            let selectionEnd = cursor.getSelectionEnd();

            if (cursorPosition.getOffset() == 0) {
                cursorPosition = CursorPosition.duplicate(cursorPosition);
                selectionEnd = CursorPosition.duplicate(selectionEnd);
                cursorPosition.naiveDecrement(this);
                selectionEnd.naiveDecrement(this);
            }

            if (!cursorPosition.inDynamicLatex(this)) {
                let paragraph = this.paragraphs[cursorPosition.getParagraphNumber()];
                paragraph.removeText(cursorPosition.getBlockNumber(), cursorPosition.getOffset(), selectionEnd.getBlockNumber(), selectionEnd.getOffset(), true);
            }
        }
    }

    public delete(cursor: Cursor) {
        if (cursor.inASelection()) {
            this.removeText(cursor);
        } else {
            // if we are at the end of a paragraph, don't delete stuff from the next one.
            if (cursor.getCursorPosition().atEndOfParagraph(this)) {
                return;
            }

            let cursorPosition = cursor.getCursorPosition();
            let selectionEnd = cursor.getSelectionEnd();
            let currentBlock = this.paragraphs[cursorPosition.getParagraphNumber()].at(cursorPosition.getBlockNumber());

            if (cursorPosition.getOffset() == currentBlock.length()) {
                cursorPosition = CursorPosition.duplicate(cursorPosition);
                selectionEnd = CursorPosition.duplicate(selectionEnd);
                cursorPosition.naiveIncrement(this);
                selectionEnd.naiveIncrement(this);
            }

            if (!cursorPosition.inDynamicLatex(this)) {
                let paragraph = this.paragraphs[cursorPosition.getParagraphNumber()];
                paragraph.removeText(cursorPosition.getBlockNumber(), cursorPosition.getOffset(), selectionEnd.getBlockNumber(), selectionEnd.getOffset(), false);
            }
        }
    }

    public cut(cursor: Cursor) {
        this.removeText(cursor);
    }

    public paste(pasteText: string, cursor: Cursor) {
        this.addText(pasteText, cursor);
    }

    public insertParagraph(cursor: Cursor, paragraphToInsert: Paragraph): [CursorPosition, CursorPosition] {
        if (cursor.inASelection() || cursor.inDynamicLatex()) {
            return [cursor.getCursorPosition(), cursor.getSelectionEnd()];
        }

        let paragraphNum = cursor.getCursorPosition().getParagraphNumber();
        let blockNumber = cursor.getCursorPosition().getBlockNumber();

        // Make sure that a Text Paragraph is always at the end of the document
        if(cursor.getCursorPosition().atEnd(this) && paragraphToInsert instanceof LatexParagraph) {
            this.addParagraph(paragraphNum + 1, paragraphToInsert);
            this.addParagraph(paragraphNum + 2, new TextParagraph())
            return [new CursorPosition(paragraphNum + 1, 0, 0), new CursorPosition(paragraphNum + 1, 0, 0)];
        }

        // Make sure that a Text Paragraph is always at the beginning of the document
        if (cursor.getCursorPosition().atBeginning() && paragraphToInsert instanceof LatexParagraph) {
            this.addParagraph(0, paragraphToInsert);
            this.addParagraph(0, new TextParagraph())
            return [new CursorPosition(1, 0, 0), new CursorPosition(1, 0, 0)];
        }

        if (cursor.getCursorPosition().atEndOfParagraph(this)) {
            this.addParagraph(paragraphNum + 1, paragraphToInsert);
            return [new CursorPosition(paragraphNum + 1, 0, 0), new CursorPosition(paragraphNum + 1, 0, 0)];
        }

        if (cursor.getCursorPosition().atBeginningOfParagraph()) {
            this.addParagraph(paragraphNum, paragraphToInsert);
            return [new CursorPosition(paragraphNum, 0, 0), new CursorPosition(paragraphNum, 0, 0)];
        }

        this.splitBlock(cursor.getCursorPosition());

        // Get right paragraph blocks
        let rightParagraphBlocks = this.at(paragraphNum).getBlocks().slice(blockNumber + 1);

        // Delete all blocks from the right side of left paragraph
        this.at(paragraphNum).getBlocks().splice(blockNumber + 1, (this.at(paragraphNum).length() - (blockNumber + 1)));

        let rightParagraph;
        if (this.at(paragraphNum) instanceof TextParagraph) {
            rightParagraph = new TextParagraph(rightParagraphBlocks);
        } else {
            rightParagraph = new LatexParagraph((rightParagraphBlocks as LatexBlock[]));
        }

        this.addParagraph(paragraphNum + 1, paragraphToInsert); // empty paragraph with cursor
        this.addParagraph(paragraphNum + 2, rightParagraph);

        return [new CursorPosition(paragraphNum + 1, 0, 0), new CursorPosition(paragraphNum + 1, 0, 0)];
    }

    public textStyle(cursor: Cursor, style: TextStyle | null): [CursorPosition, CursorPosition] {
        if (cursor.inDynamicLatex()) { // prevents user from splitting a dynamic block once they are inside it
            return [cursor.getCursorPosition(), cursor.getSelectionEnd()];
        }

        let start = CursorPosition.min(cursor.getCursorPosition(), cursor.getSelectionEnd());
        let end = CursorPosition.max(cursor.getCursorPosition(), cursor.getSelectionEnd());

        // Prevents style from never changing due to a weird edge case
        if (cursor.inASelection() && start.getOffset() == this.at(start.getParagraphNumber()).at(start.getBlockNumber()).length()) {
            start = CursorPosition.duplicate(start); // making copy becuase modifying cursorPosition
            start.naiveIncrement(this);
        }

        this.splitBlock(end);
        this.splitBlock(start);

        let leftParagraphNumber = start.getParagraphNumber();
        let rightParagraphNumber = end.getParagraphNumber();
        let leftBlockNumber = start.getBlockNumber();
        let rightBlockNumber = end.getBlockNumber();

        if (leftParagraphNumber == rightParagraphNumber && leftBlockNumber == rightBlockNumber) {
            let newBlock = this.at(leftParagraphNumber).at(leftBlockNumber + 1);
            start = new CursorPosition(leftParagraphNumber, leftBlockNumber + 1, 0);
            end = new CursorPosition(leftParagraphNumber, leftBlockNumber + 1, newBlock.length());
        } else if (leftParagraphNumber == rightParagraphNumber) {
            let rightInsideBlock = this.at(leftParagraphNumber).at(rightBlockNumber + 1);
            start = new CursorPosition(leftParagraphNumber, leftBlockNumber + 1, 0);
            end = new CursorPosition(rightParagraphNumber, rightBlockNumber + 1, rightInsideBlock.length());
        } else {
            let rightInsideBlock = this.at(rightParagraphNumber).at(rightBlockNumber);
            start = new CursorPosition(leftParagraphNumber, leftBlockNumber + 1, 0);
            end = new CursorPosition(rightParagraphNumber, rightBlockNumber, rightInsideBlock.length());
        }

        let styleValue = undefined;

        for (let i = start.getParagraphNumber(); i <= end.getParagraphNumber(); i++) {
            let leftBlockBound = 0;
            let rightBlockBound = this.at(i).length() - 1;

            if (i == start.getParagraphNumber()) {
                leftBlockBound = start.getBlockNumber();
            }

            if (i == end.getParagraphNumber()) {
                rightBlockBound = end.getBlockNumber();
            }

            for (let j = leftBlockBound; j <= rightBlockBound; j++) {
                let block = this.at(i).at(j);
                if (block instanceof TextBlock) {
                    if (styleValue == undefined) {
                        styleValue = !block.getStyle(style);
                        block.setStyle(style, styleValue);
                    } else {
                        block.setStyle(style, styleValue);
                    }
                }
            }
        }
        return [start, end];
    }

    public latexStyle(cursor: Cursor, style: LatexStyle): [CursorPosition, CursorPosition] {
        if (cursor.inASelection()) { // prevents user from selecting text and "making it Latex"
            return [cursor.getCursorPosition(), cursor.getSelectionEnd()];
        }

        if (cursor.inDynamicLatex()) { // prevents user from splitting a dynamic latex block once they are inside it
            return [cursor.getCursorPosition(), cursor.getSelectionEnd()];
        }

        let cursorPosition = cursor.getCursorPosition();
        let paragraphNumber = cursorPosition.getParagraphNumber();
        let blockNumber = cursorPosition.getBlockNumber();

        this.splitBlock(cursorPosition);

        let newBlock = new LatexBlock("", style);
        this.at(paragraphNumber).addBlock(blockNumber + 1, newBlock);

        let newCursorPosition = new CursorPosition(paragraphNumber, blockNumber + 1, 0);
        return [newCursorPosition, CursorPosition.duplicate(newCursorPosition)];
    }

    // splits the block at the given cursorPosition by adding a new block to its
    // immediate right.
    public splitBlock(cursorPosition: CursorPosition) {
        let paragraphNumber = cursorPosition.getParagraphNumber();
        let blockNumber = cursorPosition.getBlockNumber();
        let offset = cursorPosition.getOffset();

        let paragraph = this.at(paragraphNumber);
        let block = paragraph.at(blockNumber);

        let textBefore = block.substring(0, offset);
        let textAfter = block.substring(offset);

        let newBlock: Block = new TextBlock();

        if (block instanceof TextBlock) {
            newBlock = new TextBlock(textAfter, block.getAppliedStyles());
        } else if (block instanceof LatexBlock) {
            newBlock = new LatexBlock(textAfter, block.getAppliedStyle());
        }

        paragraph.addBlock(blockNumber + 1, newBlock);
        block.setText(textBefore);
    }

    // This method deletes all 0 length blocks in non-empty paragraphs (except ones that contain a non-selection cursor)
    // and updates the cursor accordingly
    public purify(cursor: Cursor) {
        for (let i = 0; i < this.paragraphs.length; i++) {
            this.paragraphs[i].purify(cursor, i);
        }
    }

    // This method coalesces all paragraphs and blocks with the same type and updates the cursor accordingly
    public coalesce(cursor: Cursor) {
        for (let parentIndex = 0; parentIndex < this.paragraphs.length - 1; parentIndex++) {
            let childIndex = parentIndex + 1;

            let parent = this.paragraphs[parentIndex];
            let child = this.paragraphs[childIndex];

            if (parent.typeEquals(child)) {
                // We need to modify the cursor since we are modifying the content indicies
                cursor.coalesceContent(parentIndex, childIndex);

                parent.getBlocks().push(...child.getBlocks());

                this.paragraphs.splice(childIndex, 1); // removes the child from the array
                parentIndex--; // want to keep the parentIndex the same on the next iteration due to deleting the index after it
            }
        }

        for (let i = 0; i < this.paragraphs.length; i++) {
            this.paragraphs[i].coalesce(cursor, i);
        }
    }

    public vanillaLatexMoveOut(cursor: Cursor, direction: number): [CursorPosition, CursorPosition, boolean] {
        let cpParagraphNumber = cursor.getCursorPosition().getParagraphNumber();
        let cpBlockNumber = cursor.getCursorPosition().getBlockNumber();
        let cpOffset = cursor.getCursorPosition().getOffset();

        let paragraph = this.paragraphs[cpParagraphNumber];
        if (paragraph instanceof TextParagraph && !cursor.inASelection()) {
            let [newBlockNumber, newOffset, changed] = paragraph.vanillaLatexMoveOut(cpBlockNumber, cpOffset, direction);
            let newCursorPosition = new CursorPosition(cpParagraphNumber, newBlockNumber, newOffset);
            return [newCursorPosition, CursorPosition.duplicate(newCursorPosition), changed];
        }

        return [CursorPosition.duplicate(cursor.getCursorPosition()), CursorPosition.duplicate(cursor.getSelectionEnd()), false];
    }

    public dynamicLatexMoveOut(cursor: Cursor, direction: number): [CursorPosition, CursorPosition] {
        let cpParagraphNumber = cursor.getCursorPosition().getParagraphNumber();
        let cpBlockNumber = cursor.getCursorPosition().getBlockNumber();
        let cpOffset = cursor.getCursorPosition().getOffset();

        let paragraph = this.paragraphs[cpParagraphNumber];

        let [newBlockNumber, newOffset] = paragraph.dynamicLatexMoveOut(cpBlockNumber, cpOffset, direction);
        let newCursorPosition = new CursorPosition(cpParagraphNumber, newBlockNumber, newOffset);
        return [newCursorPosition, CursorPosition.duplicate(newCursorPosition)];
    }

    public dynamicLatexEdit(cursor: Cursor, text: string) {
        if (cursor.inDynamicLatex()) {
            this.paragraphs[cursor.getCursorPosition().getParagraphNumber()].dynamicLatexEdit(cursor, text);
        }
    }

    /* Rendering Methods */

    public render(editor: HTMLElement, cursor: Cursor, blockMap: Map<String, Array<Array<Number>>>) {
        let scrollY = window.scrollY;
        let rem = parseFloat(getComputedStyle(editor).fontSize);

        editor.innerHTML = "";
        editor.style.marginTop = scrollY - Page.PAGE_HEIGHT/2 + "px";

        blockMap.clear();

        this.pages = [];
        let pageElementList = [];
        let pageNumber = 0;
        let pageStart = new CursorPosition(0, 0, 0);

        let docEnd = this.end();

        while (pageStart.lessThan(docEnd) || pageNumber == 0) {
            let page = new Page(pageStart, new CursorPosition(-1, -1, -1));
            this.pages.push(page);
            page.render(editor, this, cursor, pageNumber, rem, blockMap);

            let pageElement = editor.children[0]; // since we keep removing pages, it is always the first one
            pageElementList.push(pageElement);
            pageElement.remove();
            pageStart = page.getEndPosition();
            pageNumber++;
        }

        editor.style.removeProperty('margin-top');

        for (let pageElement of pageElementList) {
            editor.append(pageElement);
        }

        window.scrollTo(0, scrollY);
    }

    // Assumes that content is already fully rendered once (on page load or previously).
    // Renders the current pages that are in the viewport (and the ones above, if necessary).
    public fastRender(editor: HTMLElement, cursor: Cursor, blockMap: Map<String, Array<Array<Number>>>, 
        firstModifiedPage: number
    ): Error | null {
        let topY = window.scrollY;
        let viewPortHeight = window.innerHeight;
        let bottomY = topY + viewPortHeight;
        let remPx = parseFloat(getComputedStyle(editor).fontSize);

        let renderError = null;

        for (let i = firstModifiedPage; i < this.pages.length; i++) { // set all necessary pages dirty
            this.pages[i].setDirty();
        }

        let [firstInViewPort, lastInViewPort] = this.getPagesInViewPortBounds(topY, viewPortHeight, bottomY, remPx);
        let firstPage = Math.min(firstModifiedPage, firstInViewPort);
        let lastPage = Math.max(firstModifiedPage, lastInViewPort);
        let endingCursorPosition = CursorPosition.max(cursor.getCursorPosition(), cursor.getSelectionEnd());

        // The page elements in the viewport (and before) exist, and we know this.
        for (let pageNumber = firstPage; pageNumber <= Math.min(this.pages.length - 1, lastPage); pageNumber++) {
            let page = this.pages[pageNumber];

            if (page.getIsDirty()) {
                if (pageNumber > 0) {
                    page.setStartPosition(this.pages[pageNumber - 1].getEndPosition());
                }
                renderError = page.render(editor, this, cursor, pageNumber, remPx, blockMap);
                if (renderError != null) {break; }
            }

            if (pageNumber == lastPage && page.getEndPosition().lessThan(endingCursorPosition)) {
                lastPage++;
            }

            if (pageNumber == this.pages.length - 1 && page.getEndPosition().lessThan(this.end())) { // we need another page
                this.pages.push(new Page(page.getEndPosition(), new CursorPosition(-1, -1, -1)));

                // explicitly doing this now so that the mutation observer gets triggered when it comes into view
                editor.append(page.createNewPageElement(pageNumber + 1));
            }
        }

        window.scrollTo(0, topY);

        // Delete all blank pages
        for (let pageNumber = this.pages.length - 1; pageNumber > 0; pageNumber--) {
            if (this.pages[pageNumber].getStartPosition().greaterThanOrEqualTo(this.end())) {
                editor.children[pageNumber].remove();
                this.pages.splice(pageNumber, 1);
            }
        }

        return renderError;
    }

    // returns the start and end pages of the viewport. Inclues pages that exist and ones that
    // could exist.
    public getPagesInViewPortBounds(topY: number, viewPortHeight: number, bottomY: number, remPx: number): [number, number] {
        let toolbarHeightVh = 10; // in vh (from the EditorPage file)
        let pageTopMarginRem = 2; // in rem (from the EditorPage file)
        let pageBottomMarginRem = 1; // in rem (from style.css)

        let toolbarHeightPx = (toolbarHeightVh/100) * viewPortHeight;
        let pageTopMarginPx = pageTopMarginRem * remPx;
        let pageBottomMarginPx = pageBottomMarginRem * remPx;

        let pagesInViewport = new Set<number>();
        let pageNumber = 0;
        let pageTop = toolbarHeightPx + pageTopMarginPx;

        while (pageTop < bottomY) {
            let pageBottom = pageTop + Page.PAGE_HEIGHT;
            if (pageBottom > topY) {
                pagesInViewport.add(pageNumber); // page is in the viewport;
            }
            pageTop += (pageBottomMarginPx + Page.PAGE_HEIGHT);
            pageNumber++;
        }

        let sorted = Array.from(pagesInViewport).sort();
        return [sorted[0], sorted[sorted.length - 1]];
    }

    /* Utils */

    public end(): CursorPosition {
        let lastParagraphNumber = this.paragraphs.length - 1;
        let lastBlockNumber = this.at(lastParagraphNumber).length() - 1;
        let lastOffset = this.at(lastParagraphNumber).at(lastBlockNumber).length();
        return new CursorPosition(lastParagraphNumber, lastBlockNumber, lastOffset);
    }

    public at(index: number): Paragraph {
        return this.paragraphs[index];
    }

    public charAt(cursorPosition: CursorPosition): string {
        if (cursorPosition.getOffset() == this.at(cursorPosition.getParagraphNumber()).at(cursorPosition.getBlockNumber()).length()) {
            // duplicating because modifying cursorPosition
            cursorPosition = CursorPosition.duplicate(cursorPosition);
            cursorPosition.naiveIncrement(this);
        }
        return this.paragraphs[cursorPosition.getParagraphNumber()].charAt(cursorPosition.getBlockNumber(), cursorPosition.getOffset())
    }

    public substring(start: CursorPosition, end: CursorPosition): string {
        // start, end are in the same paragraph
        if (start.getParagraphNumber() == end.getParagraphNumber()) {
            let paragraph = this.paragraphs[start.getParagraphNumber()];
            return paragraph.substring(start.getBlockNumber(), start.getOffset(), end.getBlockNumber(), end.getOffset());
        }

        let substring = this.paragraphs[start.getParagraphNumber()].substring(start.getBlockNumber(), start.getOffset());

        for (let i = start.getParagraphNumber() + 1; i < end.getParagraphNumber(); i++) {
            substring += this.paragraphs[i].substring(0, 0);
        }

        substring += this.paragraphs[end.getParagraphNumber()].substring(0, 0, end.getBlockNumber(), end.getOffset());
        return substring;
    }

    public length(): number {
        return this.paragraphs.length;
    }

    public numPages(): number {
        return this.pages.length;
    }

    /* Setters */
    public setText(paragraph: number, block: number, text: string) {
        this.paragraphs[paragraph].setText(block, text);
    }

    /* Getters */
    public getParagraphs(): Paragraph[] {
        return this.paragraphs;
    }

    public getPages(): Page[] {
        return this.pages;
    }

    public toString(): string {
        let s = "";
        this.paragraphs.forEach(function(paragraph, index) {
            s += `Paragraph #:${index}\n`;
            s += paragraph.toString();
            s += "----------------------\n";
        })
        return s;
    }

    /* Serialization methods for state and saving to/getting from cloud */

    public serialize(): ContentData {
        let serializedParagraphs: ParagraphData[] = []; // an array of interfaces

        for (let paragraph of this.paragraphs) {
            serializedParagraphs.push(paragraph.serialize());
        }

        let contentData: ContentData = {
            paragraphs: serializedParagraphs
        }
        return contentData;
    }

    public deSerialize(data: ContentData) {
        this.paragraphs = new Array<Paragraph>()

        for (let paragraphData of data.paragraphs) {
            let paragraph: Paragraph = new TextParagraph();;

            if (paragraphData.type == ParagraphDataType.LATEX) {
                paragraph = new LatexParagraph();
            }
            paragraph.deSerialize(paragraphData);
            this.paragraphs.push(paragraph);
        }
    }
}
