import { Content } from "./Content"
import { Cursor } from "./Cursor"
import { StateData } from "../Common";

export class State {
    private prev: StateData[];
    private next: StateData[];
    private cont: Content;
    private curs: Cursor;

    constructor(content: Content, cursor: Cursor) {
        this.cont = content;
        this.curs = cursor;
        this.prev = [];
        this.next = [];
        let currentState: StateData = {
            contentData: this.cont.serialize(), 
            cursorData: this.curs.serialize(),
            firstModifiedPageData: 0,
        }
        // the last element of prev stores the current state
        this.prev.push(currentState);
    }

    public save(firstModPage: number) {
        let currentState: StateData = {
            contentData: this.cont.serialize(),
            cursorData: this.curs.serialize(),
            firstModifiedPageData: firstModPage,
        }
        let prevState: StateData = this.prev[this.prev.length - 1];
        if (JSON.stringify(currentState) != JSON.stringify(prevState)) {
            this.prev.push(currentState);
        }
        this.next = []; // clearing the future because we saved
    }

    public undo() {
        if (this.prev.length > 1) { // if length = 1, cannot undo, as this is the current state
            let currentStateData = this.prev.pop();
            this.next.push(currentStateData!);
            this.consumeCurrentData();
        }
    }

    public redo() {
        let nextStateData = this.next.pop();
        if (nextStateData != undefined) { // returns undefined if it is empty
            this.prev.push(nextStateData);
            this.consumeCurrentData();
        }
    }

    public content(): Content {
        return this.cont;
    }

    public cursor(): Cursor {
        return this.curs;
    }

    public firstModifiedPage(): number {
        let currentStateData = this.prev[this.prev.length - 1];
        return currentStateData.firstModifiedPageData;
    }

    private consumeCurrentData() {
        let newCurrentSateData = this.prev[this.prev.length - 1];
        this.cont.deSerialize(newCurrentSateData.contentData);
        this.curs.deSerialize(newCurrentSateData.cursorData);
    }

}
