import { Box } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { colors } from "../theme/theme";

export const AnimatedBackground = () => {
    const blobAnimation = keyframes`
    0% { transform: translate(0px, 0px) scale(1); }
    33% { transform: translate(30px, -50px) scale(1.1); }
    66% { transform: translate(-20px, 20px) scale(0.9); }
    100% { transform: translate(0px, 0px) scale(1); }
    `;

    return (
        <Box>
            {/* Animated background shapes */}
            <Box
                position="absolute"
                top="-16"
                left="-16"
                w="64"
                h="64"
                bg={colors.pageBackgrounds.blobColors.yellowBlob}
                borderRadius="full"
                filter="blur(70px)"
                opacity={0.7}
                animation={`${blobAnimation} 7s infinite`}
            />
            <Box
                position="absolute"
                top="32"
                right="-16"
                w="64"
                h="64"
                bg={colors.pageBackgrounds.blobColors.pinkBlob}
                borderRadius="full"
                filter="blur(70px)"
                opacity={0.7}
                animation={`${blobAnimation} 7s infinite 2s`}
            />
            <Box
                position="absolute"
                bottom="-16"
                left="32"
                w="64"
                h="64"
                bg={colors.pageBackgrounds.blobColors.purpleBlob}
                borderRadius="full"
                filter="blur(70px)"
                opacity={0.7}
                animation={`${blobAnimation} 7s infinite 4s`}
            />
        </Box>
    );
};
