import {
    Flex,
    Heading,
    Link as ChakraLink,
    Button,
    useColorModeValue,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { colors } from "../theme/theme";

export const VerifyEmailPage = () => {
    const bgGradient = useColorModeValue(
        `linear(to-br, ${colors.pageBackgrounds.light.startGradient}, ${colors.pageBackgrounds.light.endGradient})`,
        `linear(to-br, ${colors.pageBackgrounds.dark.startGradient}, ${colors.pageBackgrounds.dark.endGradient})`
    );

    return (
        <Flex
            bgGradient={bgGradient}
            flexDirection="column"
            minHeight="100vh"
            position="relative"
            overflow="hidden"
            justifyContent="center"
            alignItems="center"
        >
            <Heading fontSize="4xl">Please verify your email!</Heading>
            <Button
                p={5}
                bgColor={colors.accentYellow}
                size="3xl"
                mt="5vh"
                fontSize="3xl"
                fontFamily="fonts.heading"
                fontWeight={700}
                borderRadius="0.5rem"
                color="black"
                _hover={{ bg: "hoverYellow" }}
            >
                <ChakraLink
                    _hover={{ textDecoration: "none" }}
                    as={ReactRouterLink}
                    to="/login"
                >
                    Verified email
                </ChakraLink>
            </Button>
        </Flex>
    );
};
