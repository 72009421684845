import { useRef } from "react";
import { MiniDocument } from "./MiniDocument";
import { Controller } from "../editor/Controller";
import { Box } from "@chakra-ui/react";
import { MiniToolbar } from "./MiniToolbar";

export const MiniEditor = () => {
    const controllerRef = useRef<Controller | null>(null);

    return (
        <Box>
            <MiniToolbar ref={controllerRef} renderError={() => {}} />
            <MiniDocument
                ref={controllerRef}
                overflow="hidden"
                minWidth="100%"
                height="50vh"
                color="black"
            />
        </Box>
    );
};
