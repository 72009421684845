import { Flex, Text, Center } from "@chakra-ui/react";

export const Footer = (props: any) => {
    return (
        <Flex w="100%" h="5vh" justifyContent="center" {...props}>
            <Center>
                <Text fontSize="sm" color="white">
                    Made with ❤️ in Seattle
                </Text>
            </Center>
        </Flex>
    );
};
