import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthData } from "../../Common";

const initialState: AuthData = {
  isLoggedIn: false,
  userData: { firstName: "", lastName: "", email: "" },
  token: null
}

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    updateData: (state, action) => {
      Object.assign(state.userData, action.payload);
    },
    setLoggedIn(state, action: PayloadAction<boolean>) {
        state.isLoggedIn = action.payload;
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateData, setLoggedIn, setToken } = authSlice.actions;

export default authSlice.reducer;