// 1. import `extendTheme` function
import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import '@fontsource/roboto'

// 2. Add your color mode config
const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

const styles = {
    dark: {
        bg: 'gray.900',
        color: 'white'
    },
    light: {
        bg: '#622CC2',
        color: 'white'
    }
}

// Brand colors
const primaryPurple = "#622CC2";
const darkPurple = "purple.900";
const accentYellow = "#FACC14";

export const colors = {
    primaryPurple: primaryPurple,
    darkPurple: darkPurple,
    accentYellow: accentYellow,
    navbarHoverYellow: "yellow.300",
    hoverPurple: "#4D229A", // only used on the user's name in the navbar when they are logged in
    hoverYellow: "#EBC645", // only used on the confirm email page

    pageBackgrounds: {
        blobColors: {
            yellowBlob: "yellow.400",
            pinkBlob: "pink.400",
            purpleBlob: "purple.400",
        },
        light: {
            startGradient: primaryPurple,
            endGradient: primaryPurple,
        },
        dark:  {
            startGradient: "gray.900",
            endGradient: darkPurple
        }
    },
    landingPage: {
        latexYellow: "yellow.200",
        actionButton: {
            showMeHowYellow: accentYellow,
            showMeHowButtonTextColor: darkPurple,
            signUpBlue: "#316ceb",
            signUpButtonTextColor: "white",
        },
        light: {
            codeBg: "whiteAlpha.200",
        },
        dark: {
            codeBg: "whiteAlpha.100",
        }
    },
    editor: {
        light: {
            toolbarBackground: "white",
            documentPageColor: "white",
            documentBackgroundColor: "#2B2F41",
            documentFontColor: "black",
            codeBackgroundColor: "#E8EAED",
        },
        darkPrimary: {
            toolbarBackground: "#080808",
            documentPageColor: "#1B202B",
            documentBackgroundColor: "#2B2F41",
            documentFontColor: "white",
            codeBackgroundColor: "#282A40",
        },
        darkSecondary: {
            toolbarBackground: "#080808",
            documentPageColor: "#1B191C",
            documentBackgroundColor: "black",
            documentFontColor: "white",
            codeBackgroundColor: "#37373C",
        },
    }
}

// Brand fonts
const fonts = {
    heading: `'Open Sans', sans-serif`,
    body: `'Open Sans', sans-serif`,
};

const fontWeights = {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
    extraBold: 800,
}

// 3. extend the theme
const theme = extendTheme({ config }, { colors }, { fonts }, { fontWeights }, { styles })

export default theme