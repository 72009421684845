import CryptoJS from 'crypto-js';

export class Encrypter {

    static encrypt(text: string): string {
        let aes = CryptoJS.AES.encrypt(text, process.env.REACT_APP_CRYPTO_SECRET_KEY!).toString();
        let b64 = CryptoJS.enc.Base64.parse(aes);
        let cipherText = b64.toString(CryptoJS.enc.Hex);
        return cipherText;
    }

    static decrypt(text: string): [string, Error | null] {
        try {
            let hex = CryptoJS.enc.Hex.parse(text);
            let b64 = hex.toString(CryptoJS.enc.Base64);
            let decrypted = CryptoJS.AES.decrypt(b64, process.env.REACT_APP_CRYPTO_SECRET_KEY!);
            let plainText = decrypted.toString(CryptoJS.enc.Utf8);
            return [plainText, null];
        } catch (error: any) {
            return ["", error];
        }
    }
}