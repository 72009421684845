import { Content } from "./Content"
import { Paragraph } from "./Paragraph"
import { Block } from "./Block";
import { TextBlock } from "./TextBlock"
import { LatexBlock, LatexStyle } from "./LatexBlock";
import { Cursor } from "./Cursor"
import { ParagraphData, ParagraphDataType, BlockData, BlockDataType } from "../Common"

export class TextParagraph extends Paragraph {
    protected blocks: Block[];

    constructor(blocks = new Array<Block>()) {
        super();
        this.blocks = blocks;

        if (this.blocks.length == 0) {
            this.blocks.push(new TextBlock());
        }
    }

    public addBlock(index: number, block: Block) {
        this.blocks.splice(index, 0, block);
    }

    // Returns a rendered HTML element representing a chunk of this paragraph. The chunk is defined by
    // a given startBlockNumber, startOffset, and chunkSize. This method also returns a boolean
    // that is true if the end of this paragraph is included in the chunk and false otherwise.
    public renderChunk(content: Content, cursor: Cursor, paragraphNumber: number, 
        startBlockNumber: number, startOffset: number, chunkSize: number
    ): [HTMLElement | null, boolean] {
        let div = document.createElement("div");
        div.classList.add("paragraph");
        div.classList.add("textparagraph");
        div.setAttribute('content-structure', `${paragraphNumber}`);

        let index = startBlockNumber;
        let block = this.blocks[index];

        if (startOffset == block.length() && block.length() != 0) {
            startOffset = 0;
            index++;
            block = this.blocks[index];
        }

        if (index >= this.blocks.length) {
            return [null, true];
        }

        let [blockElement, length, blockFinishedRendering] = block.renderChunk(content, cursor, paragraphNumber, index, startOffset, chunkSize);
        div.appendChild(blockElement);
        chunkSize -= length;
        index++;

        while (chunkSize > 0 && index < this.length()) {
            block = this.blocks[index];

            [blockElement, length, blockFinishedRendering] = block.renderChunk(content, cursor, paragraphNumber, index, 0, chunkSize);
            div.appendChild(blockElement);
            chunkSize -= length;
            index++;
        }

        return [div, index >= this.length() && blockFinishedRendering];
    }

    public render(content: Content, cursor: Cursor, paragraphNumber: number): HTMLElement {
        let div = document.createElement("div");
        div.classList.add("paragraph");
        div.classList.add("textparagraph");
        div.setAttribute('content-structure', `${paragraphNumber}`);

        this.blocks.forEach((block: Block, index) => {
            div.appendChild(block.render(content, cursor, paragraphNumber, index));
        });
        return div;
    }

    public vanillaLatexMoveOut(cpBlockNumber: number, cpOffset: number, direction: number): [number, number, boolean] {
        let block = this.blocks[cpBlockNumber];
        if (block instanceof LatexBlock && block.getStyle(LatexStyle.VANILLA)) {
            if (direction == -1 && cpBlockNumber == 0 && cpOffset == 0) { // add block at beginning of the paragraph
                this.addBlock(0, new TextBlock());
                return [1, 0, true]
            } else if (direction == 1 && cpBlockNumber == this.length() - 1 && block.length() == cpOffset) {
                this.addBlock(this.blocks.length, new TextBlock());
                let newOffset = this.blocks[cpBlockNumber].length();
                return [cpBlockNumber, newOffset, true];
            }
        }

        return [cpBlockNumber, cpOffset, false];
    }

    public dynamicLatexMoveOut(cpBlockNumber: number, cpOffset: number, direction: number): [number, number] {
        return super.dynamicLatexMoveOutHelper(new TextBlock(), cpBlockNumber, cpOffset, direction);
    }

    /* Serialization methods for state and saving to/getting from cloud */

    public serialize(): ParagraphData {
        let serializedBlocks: BlockData[] = [];

        for (let block of this.blocks) {
            serializedBlocks.push(block.serialize());
        }

        let paragraphData: ParagraphData = {
            type: ParagraphDataType.TEXT,
            blocks: serializedBlocks,
        }

        return paragraphData;
    }

    public deSerialize(data: ParagraphData) {
        this.blocks = new Array<Block>();

        for (let blockData of data.blocks) {
            let block: Block = new TextBlock();

            if (blockData.type == BlockDataType.LATEX) {
                block = new LatexBlock();
            }
            block.deSerialize(blockData);
            this.blocks.push(block);
        }
    }
}