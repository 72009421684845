import { Content } from "./Content"
import { Block } from "./Block";
import { Cursor } from "./Cursor"
import { ParagraphData } from "../Common"

export abstract class Paragraph {
    protected blocks!: Block[];

    // Overridden methods
    abstract addBlock(index: number, block: Block): void;
    abstract renderChunk(content: Content, cursor: Cursor, paragraphNumber: number, startBlockNumber: number, startOffset: number, chunkSize: number): [HTMLElement | null, boolean];
    abstract render(content: Content, cursor: Cursor, paragraphNumber: number): HTMLElement;
    abstract dynamicLatexMoveOut(cpBlockNumber: number, cpOffset: number, direction: number): [number, number];
    abstract serialize(): ParagraphData;
    abstract deSerialize(data: ParagraphData): void;

    public addText(
        textToAdd: string, 
        leftEndBlockNumber: number,
        leftEndOffset: number,
        rightEndBlockNumber: number,
        rightEndOffset: number
    ) {
        // if selection is within a single block
        if (leftEndBlockNumber == rightEndBlockNumber) {
            this.blocks[leftEndBlockNumber].addText(textToAdd, leftEndOffset, rightEndOffset);
        } else {
            // delete from offset in left end -> end of left end
            // add to left end
            let leftBlock = this.blocks[leftEndBlockNumber];
            leftBlock.addText(textToAdd, leftEndOffset, leftBlock.length());

            // delete from 0 in right end -> offset of right end
            let rightBlock = this.blocks[rightEndBlockNumber];
            rightBlock.removeText(0, rightEndOffset);

            // delete all middle blocks
            for (let i = leftEndBlockNumber + 1; i < rightEndBlockNumber; i++) {
                this.blocks.splice(leftEndBlockNumber + 1, 1);
            }
        }
    }

    public removeText(leftEndBlockNumber: number, leftEndOffset: number,
            rightEndBlockNumber: number, rightEndOffset: number, backwards: boolean = true) {
        // if selection is within a single block
        if (leftEndBlockNumber == rightEndBlockNumber) {
            this.blocks[leftEndBlockNumber].removeText(leftEndOffset, rightEndOffset, backwards);
        } else {
            // delete from offset in left end -> end of left end
            let leftBlock = this.blocks[leftEndBlockNumber];
            if (leftEndOffset != leftBlock.length()) {
                leftBlock.removeText(leftEndOffset, leftBlock.length(), backwards);
            }

            // delete from 0 in right end -> offset of right end
            let rightBlock = this.blocks[rightEndBlockNumber];
            rightBlock.removeText(0, rightEndOffset, backwards);

            // delete all middle blocks
            for (let i = leftEndBlockNumber + 1; i < rightEndBlockNumber; i++) {
                this.blocks.splice(leftEndBlockNumber + 1, 1);
            }
        }
    }

    // This method deletes all 0 length blocks in this paragraph, with two caveats:
    //     1) This method will not delete a 0 length block if it is the only block in the paragraph, and
    //     2) This method will not delete any 0 length blocks that contain a non-selection cursor.
    //
    // Takes in the cursor and paragraphIndex, the index of this paragraph in content.
    // Updates the cursor accordingly.
    public purify(cursor: Cursor, paragraphIndex: number) {
        for (let i = 0; i < this.blocks.length; i++) {
            if (this.blocks[i].length() == 0 && this.length() > 1) {

                // If the following is not true: the cursor is inside a 0 length block and is not in a selection
                if (!(
                    cursor.getCursorPosition().getParagraphNumber() == paragraphIndex &&
                    cursor.getSelectionEnd().getParagraphNumber() == paragraphIndex &&
                    cursor.getCursorPosition().getBlockNumber() == i &&
                    cursor.getSelectionEnd().getBlockNumber() == i)
                ) {
                    cursor.purifyParagraph(paragraphIndex, i);
                    this.blocks.splice(i, 1);
                    i--; // want to keep i the same on the next iteration due to deleting it
                }
            }
        }
    }

    // This method coalesces all blocks in this paragraph with the same style
    // and updates the cursor accordingly. Takes in the cursor and paragraphIndex,
    // the index of this paragraph in content.
    public coalesce(cursor: Cursor, paragraphIndex: number) {
        for (let parentIndex = 0; parentIndex < this.blocks.length - 1; parentIndex++) {
            let childIndex = parentIndex + 1;

            let parent = this.blocks[parentIndex];
            let child = this.blocks[childIndex];

            if (parent.styleEquals(child)) {
                // We need to modify the cursor since we are modifying the content indicies
                cursor.coalesceParagraph(paragraphIndex, parentIndex, childIndex);

                parent.setText(parent.getText() + child.getText());

                this.blocks.splice(childIndex, 1); // removes the child from the array
                parentIndex--; // want to keep the parentIndex the same on the next iteration due to deleting the index after it
            }
        }
    }

    // Called when we move out of dynamic latex (mathquill) in this paragraph. Takes in
    // the new block to potentially add, the cursorPosition block number and offset,
    // and returns the new [cursorPositionBlockNumber, cursorPositionOffset]
    protected dynamicLatexMoveOutHelper(block: Block, cpBlockNumber: number, cpOffset: number, direction: number): [number, number] {
        if (direction == -1 && cpBlockNumber == 0) { // add block at beginning of the paragraph
            this.addBlock(0, block);
            return [1, 0]
        } else if (direction == 1 && cpBlockNumber == this.length() - 1) {
            this.addBlock(this.blocks.length, block);
            let newOffset = this.blocks[cpBlockNumber].length();
            return [cpBlockNumber, newOffset];
		}
        return [cpBlockNumber, cpOffset];
    }

    public dynamicLatexEdit(cursor: Cursor, text: string) {
        if (cursor.inDynamicLatex()) {
            this.blocks[cursor.getCursorPosition().getBlockNumber()].setText(text);
        }
    }

    public typeEquals(other: Paragraph): boolean {
       return this.constructor === other.constructor;
    }

    /* Utils */

    public at(index: number): Block {
        return this.blocks[index];
    }

    public charAt(blockNumber: number, offset: number): string {
        return this.blocks[blockNumber].charAt(offset);
    }

    public substring(
        startBlockNumber: number,
        startOffset: number,
        endBlockNumber: number = this.length() - 1,
        endOffset: number = this.at(this.length() - 1).length()
    ): string {
        // start, end are in the same block
        if (startBlockNumber == endBlockNumber) {
            return this.blocks[startBlockNumber].substring(startOffset, endOffset);
        }

        let substring = this.blocks[startBlockNumber].substring(startOffset);

        for (let i = startBlockNumber + 1; i < endBlockNumber; i++) {
            substring += this.blocks[i].getText();
        }

        substring += this.blocks[endBlockNumber].substring(0, endOffset);
        return substring;
    }

    public length(): number {
        return this.blocks.length;
    }

    public isEmpty(): boolean {
        return this.blocks[0].getText() == "" && this.length() == 1;
    }

    /* Setters */
    public setText(block: number, text: string) {
        this.blocks[block].setText(text);
    }

    /* Getters */
    public getBlocks(): Block[] {
        return this.blocks;
    }

    public toString(): string {
        let s = "";
        this.blocks.forEach(function(block, index) {
            s += `\tBlock #:${index}\n`;
            s += `\t${block.getText()}\n`;
        })
        return s;
    }
}