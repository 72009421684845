import {
    ButtonGroup,
    Flex,
    Heading,
    Icon,
    IconButton,
    Spacer,
    Button,
    Tooltip,
    Spinner
} from "@chakra-ui/react";
import {
    MdFormatBold,
    MdFormatItalic,
    MdFormatUnderlined,
    MdStrikethroughS,
    MdFormatClear,
} from "react-icons/md";
import { FaRedo, FaSave, FaUndo, FaDownload } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useState, useEffect, forwardRef } from "react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { ColorModeSwitcherLocation, ToolbarProps } from "../Common";
import { Encrypter } from "../Encrypter";
import { AwsLibrary } from "../Common";
import { useAuth } from "../auth/Authentication";

export const Toolbar = forwardRef((props: ToolbarProps, controllerRef: any) => {
    let { encryptedDocumentName } = useParams();
    let [documentName, setDocumentName] = useState("");
    let [downloading, setDownloading] = useState(false);

    let { useGetUserData } = useAuth();
    let userData = useGetUserData();

    useEffect(() => {
        if (encryptedDocumentName != undefined) {
            let [fullDocumentName, error] = Encrypter.decrypt(
                encryptedDocumentName!
            );

            if (error == null) {
                let regexMatch = fullDocumentName.match(/\.edu\/(.*)\.json/);
                if (regexMatch != null && regexMatch.length > 1) {
                    setDocumentName(regexMatch[1]);
                }
            }
        }
    }, []);

    const generatePDF = async () => {
        setDownloading(true);
        // Save document first
        await AwsLibrary.saveDocument(
            controllerRef.current.getState().content().serialize(),
            userData.email,
            documentName
        ).then(() => {
            controllerRef.current.handleSaveInCloud();
        })

        const response = await fetch("https://s0sliga4o5.execute-api.us-east-2.amazonaws.com/prod/download-pdf", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                editorUrl: window.location.href,
            }),
        });

        if (!response.ok) {
            setDownloading(false);
            alert("Network request was not okay");
        }

        const base64String = await response.text();
        const binaryString = atob(base64String);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = documentName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setDownloading(false);
    };

    const handleBold = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleBold(event);
        }
    };

    const handleItalic = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleItalic(event);
        }
    };

    const handleUnderline = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleUnderline(event);
        }
    };

    const handleStrikethrough = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleStrikethrough(event);
        }
    };

    const handleClearFormatting = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleClearFormatting(event);
        }
    };

    const handleDynamicLatex = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleDynamicLatex(event);
        }
    };

    const handleVanillaLatex = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleVanillaLatex(event);
        }
    };

    const handleLatexParagraph = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleLatexParagraph(event);
        }
    };

    const handleSave = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            AwsLibrary.saveDocument(
                controllerRef.current.getState().content().serialize(),
                userData.email,
                documentName
            ).then(() => {
                controllerRef.current.handleSaveInCloud();
                props.setShowSaved(true);
            });
        }
    };

    const handleUndo = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleUndo();
        }
    };

    const handleRedo = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (controllerRef.current) {
            controllerRef.current.handleRedo();
        }
    };

    return (
        <Flex {...props.styles} justifyContent="center" alignItems="center">
            <Heading fontSize="2xl" fontFamily="fonts.heading" fontWeight={600}>
                {documentName}
            </Heading>
            <Spacer />
            <ButtonGroup>
                <IconButton
                    onClick={handleBold}
                    fontSize="3xl"
                    aria-label="bold-button"
                    variant="ghost"
                    icon={<Icon as={MdFormatBold} />}
                />
                <IconButton
                    onClick={handleItalic}
                    fontSize="3xl"
                    aria-label="italic-button"
                    variant="ghost"
                    icon={<Icon as={MdFormatItalic} />}
                />
                <IconButton
                    onClick={handleUnderline}
                    fontSize="3xl"
                    aria-label="underline-button"
                    variant="ghost"
                    icon={<Icon as={MdFormatUnderlined} />}
                />
                <IconButton
                    onClick={handleStrikethrough}
                    fontSize="3xl"
                    aria-label="strikethrough-button"
                    variant="ghost"
                    icon={<Icon as={MdStrikethroughS} />}
                />
                <IconButton
                    onClick={handleClearFormatting}
                    fontSize="3xl"
                    aria-label="clear-formatting-button"
                    variant="ghost"
                    icon={<Icon as={MdFormatClear} />}
                />
            </ButtonGroup>
            <Spacer />
            <ButtonGroup>
                <Button
                    onClick={handleDynamicLatex}
                    fontSize="3xl"
                    aria-label="latex-button"
                    variant="ghost"
                >
                    L
                </Button>
                <Button
                    onClick={handleVanillaLatex}
                    fontSize="3xl"
                    aria-label="latex-button"
                    variant="ghost"
                >
                    VL
                </Button>
                <Button
                    onClick={handleLatexParagraph}
                    fontSize="3xl"
                    aria-label="latex-paragraph-button"
                    variant="ghost"
                >
                    LP
                </Button>
            </ButtonGroup>
            <Spacer />
            <Tooltip
                label={props.renderError != null ? "Fix all errors before saving" : ""}
                isDisabled={props.renderError == null}
                shouldWrapChildren
            >
                <IconButton
                    onClick={handleSave}
                    fontSize="3xl"
                    aria-label="save-button"
                    variant="ghost"
                    isDisabled={props.renderError != null}
                    icon={<Icon as={FaSave} />}
                />
            </Tooltip>
            <IconButton
                onClick={handleUndo}
                fontSize="2xl"
                aria-label="undo-button"
                variant="ghost"
                marginLeft="0.5rem"
                icon={<Icon as={FaUndo} />}
            />
            <IconButton
                onClick={handleRedo}
                fontSize="2xl"
                aria-label="redo-button"
                variant="ghost"
                marginLeft="0.5rem"
                icon={<Icon as={FaRedo} />}
            />
            {
                downloading ?
                    <Spinner
                        fontSize="2xl"
                        aria-label="download-button"
                        variant="ghost"
                        marginLeft="0.5rem"
                    /> :
                    <IconButton
                        onClick={generatePDF}
                        fontSize="2xl"
                        aria-label="download-button"
                        variant="ghost"
                        marginLeft="0.5rem"
                        icon={<Icon as={FaDownload} />}
                    />
            }
            <Spacer />
            <ColorModeSwitcher location={ColorModeSwitcherLocation.EDITOR} />
        </Flex>
    );
});
