import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import { IntroSection } from "../components/IntroSection";
import { AnimatedBackground } from "../components/AnimatedBackground";
import { colors } from "../theme/theme";

export const HomePage = () => {
    const bgGradient = useColorModeValue(
        `linear(to-br, ${colors.pageBackgrounds.light.startGradient}, ${colors.pageBackgrounds.light.endGradient})`,
        `linear(to-br, ${colors.pageBackgrounds.dark.startGradient}, ${colors.pageBackgrounds.dark.endGradient})`
    );

    return (
        <Box
            minHeight="100vh"
            bgGradient={bgGradient}
            position="relative"
            overflow="hidden"
        >
            <AnimatedBackground />
            <Flex direction="column" minH="100vh">
                <Navbar />
                <IntroSection />
                <Footer />
            </Flex>
        </Box>
    );
};
