import { Box } from "@chakra-ui/react";
import { forwardRef, useEffect, useRef, useState } from "react";
import "../editor/style.css";
import { Controller } from "../editor/Controller";
import { Content } from "../editor/Content";

export const MiniDocument = forwardRef((props: any, controllerRef: any) => {
    const editor = useRef<HTMLElement>(null);
    const latexNavigationActions = new Set<string>(["Up", "Down", "Left", "Right", "Enter", "Tab"]);

    let sequences = [
        "Underline",
        "My ",
        "Bold",
        "NEW",
        "Bold",
        " Latex Document",
        "Underline",
        "Enter",
        "Author: Happy Author (You)",
        "Enter",
        "Date: Today",
        "Enter",
        "Enter",
        "Bold",
        "Problem 1:",
        "Bold",
        " Name two cool things about ",
        ["e", "^", "x", "Right", "Right"],
        "Enter",
        "Tab",
        "1) ",
        ["e", "^", "x", "Down", "=", "\\", "i", "n", "t", "Enter", "Tab", "Tab", "e", "^", "x", "Down", " ", "d", "x", "Right"],
        "Enter",
        "Tab",
        "2) ",
        [
            "e", "^", "x", "Down", "=", "\\", "s", "u", "m", "Enter", "n", "=", "0", "Up",
            "\\", "i", "nft", "y", "Enter", "Right", "x^n", "Down", "/", "n", "!", "Right", "Right"
        ],
        "Enter",
        "Enter",
        "Italic",
        "This is a mini version of Parchmynt's editor. Type and try it out! Instructions on use are in the \"Documentation\" page on this website.",
        "Italic",
        "Enter",
        "Enter",
    ]

    useEffect(() => {
        if (editor.current) {
            editor.current.focus({ preventScroll: true });
            createEmptyEditor();
            sleep(1000);
            typeContent();
        }
    }, []);

    const createEmptyEditor = () => {
        if (editor.current) {
            const newContent = new Content();
            controllerRef.current = new Controller(
                () => {},
                editor.current!,
                newContent
            );
        }
    };

    const sleep = (ms: number) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const makeMathField = (number: number) => {
        controllerRef.current!.latexStyleHandler("dynamic");
        const latexBlock = document.querySelectorAll(".latexblock")![number];

        const mathField = controllerRef.current!.MQ.MathField(latexBlock, {
            spaceBehavesLikeTab: true,
        });
        mathField.focus();
        return mathField;
    }

    const typeString = async (s: string) => {
        if (s == "Bold" || s == "Underline" || s == "Italic") {
            controllerRef.current!.textStyleHandler(s.toLowerCase());
        } else if (s == "Enter" || s == "Tab") {
            typeLetter(s);
        } else {
            for (let i = 0; i < s.length; i++) {
                typeLetter(s[i])
                await sleep(30);
            }
        }
    }

    const typeLetter = (letter: string) => {
        let event = new KeyboardEvent("keydown", {
            key: letter,
            bubbles: true,
        });
        editor.current!.dispatchEvent(event)
    }

    const typeLatex = async (seq: string[], mathFieldIndex: number) => {
        let mathField = makeMathField(mathFieldIndex);
        for (let i = 0; i < seq.length; i++) {
            typeLatexCharacter(seq[i], mathField);
            await sleep(100);
        }
    }

    const typeLatexCharacter = (key: string, mathField: any) => {
        if (latexNavigationActions.has(key)) {
            mathField.keystroke(key);
        } else {
            mathField.typedText(key);
        }
    }

    const typeContent = async () => {
        let mathFieldIndex = 0;
        for (let i = 0; i < sequences.length; i++) {
            if (typeof sequences[i] == "string") {
                await typeString(sequences[i] as string);
            } else {
                await typeLatex(sequences[i] as string[], mathFieldIndex++);
            }
        }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (controllerRef.current) {
            controllerRef.current!.handleKeyDown(event);
        }
    };

    const handleMouseDown = (event: MouseEvent) => {
        if (controllerRef.current) {
            controllerRef.current.handleMouseDown(event);
        }
    };

    return (
        <Box
            className="editor"
            ref={editor}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            tabIndex={0}
            {...props}
        />
    );
});
