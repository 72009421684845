import {
    Box,
    Code,
    Container,
    Flex,
    GridItem,
    Heading,
    Link,
    List,
    ListItem,
    OrderedList,
    UnorderedList,
    useColorModeValue,
} from "@chakra-ui/react";
import { Footer } from "../components/Footer";
import { AnimatedBackground } from "../components/AnimatedBackground";
import { colors } from "../theme/theme";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import { ColorModeSwitcherLocation } from "../Common";
import { CodeBlock } from "../components/CodeBlock";
import { Navbar } from "../components/Navbar";

export const SupportPage = () => {
    const bgGradient = useColorModeValue(
        `linear(to-br, ${colors.pageBackgrounds.light.startGradient}, ${colors.pageBackgrounds.light.endGradient})`,
        `linear(to-br, ${colors.pageBackgrounds.dark.startGradient}, ${colors.pageBackgrounds.dark.endGradient})`
    );

    return (
        <Box
            bgGradient={bgGradient}
            minHeight="100vh"
            position="relative"
            overflow="hidden"
            w="100%"
            color="white"
        >
            <AnimatedBackground />
            <Navbar />
            <Container maxW="4xl" mt={20}>
                <Heading as="h1" size="lg" mb={2} id="basics">
                    Support
                </Heading>
                <Box mb={6}>
                    Writing Latex can be difficult! If you are in need of any
                    support, please do not hesitate to reach out to{" "}
                    <Link
                        href="mailto: parchmyntedu@gmail.com"
                        textDecor="underline"
                        textColor="blue.400"
                    >
                        parchmyntedu@gmail.com
                    </Link>{" "}
                    with the subject line "Support".
                    <strong>
                        {" "}
                        Please make sure to email us using your UW or CSE NetID.{" "}
                    </strong>
                    <br />
                    <br />
                    If you have an urgent issue (i.e. Parchmynt crashes an hour
                    before a homework deadline), please use the subject line
                    "Urgent".
                    <br />
                    <br />
                    If you want to file a bug report, please use the subject
                    line "Bug".
                    <br />
                    <br />
                    For general feedback or anything else, surprise us!
                    <br />
                    <br />
                    We will do our best to respond to all inquires within 1-2
                    days.
                </Box>
            </Container>
            <Footer position="absolute" left="0" bottom="0" padding={3} />
        </Box>
    );
};
