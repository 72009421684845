import { Box } from "@chakra-ui/react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Controller } from "../editor/Controller";
import { AwsLibrary, ContentData, DocumentProps } from "../Common";
import { Encrypter } from "../Encrypter";
import { Content } from "../editor/Content";
import "../editor/style.css";

export const Document = forwardRef((props: DocumentProps, controllerRef: any) => {
    const navigate = useNavigate();
    let { encryptedDocumentName } = useParams();
    const editor = useRef<HTMLElement>(null);
    const [renderError, setRenderError] = useState<Error | null>(null);

    useEffect(() => {
        props.setRenderError(renderError);
    }, [renderError]);

    useEffect(() => {
        const handleCopy = (event: ClipboardEvent) => {
            if (editor.current) {
                controllerRef.current.handleCopy(event);
            }
        };

        const handleCut = (event: ClipboardEvent) => {
            if (editor.current) {
                controllerRef.current.handleCut(event);
            }
        };

        const handlePaste = (event: ClipboardEvent) => {
            if (editor.current) {
                controllerRef.current.handlePaste(event);
            }
        };

        document.addEventListener("copy", handleCopy);
        document.addEventListener("cut", handleCut);
        document.addEventListener("paste", handlePaste);

        return () => {
            document.removeEventListener("copy", handleCopy);
            document.removeEventListener("cut", handleCut);
            document.removeEventListener("paste", handlePaste);
        };
    }, []);

    useEffect(() => {
        if (editor.current) {
            editor.current.focus({ preventScroll: true }); // Focus on the editor on page load
            if (encryptedDocumentName != undefined) {
                let [fullDocumentName, error] = Encrypter.decrypt(
                    encryptedDocumentName!
                );

                if (error != null) {
                    navigate("/error");
                } else {
                    fetchDocument(fullDocumentName);
                }
            } else {
                navigate("/error");
            }
        }
    }, []);

    const fetchDocument = async (documentKey: string) => {
        let [doc, error] = await AwsLibrary.getDocument(documentKey);
        if (error != null || doc == null) {
            navigate("/error");
        } else if (editor.current) {
            let contentData: ContentData = JSON.parse(doc.content!);
            let content = new Content();
            content.deSerialize(contentData);

            controllerRef.current = new Controller(setRenderError, editor.current, content);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (controllerRef.current) {
            controllerRef.current!.handleKeyDown(event);
        }
    };

    const handleMouseDown = (event: MouseEvent) => {
        if (controllerRef.current) {
            controllerRef.current.handleMouseDown(event);
        }
    };

    return (
        <Box
            className="editor"
            ref={editor}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            tabIndex={0}
            {...props.styles}
        />
    );
});
