import { Flex, Heading, useColorModeValue } from "@chakra-ui/react";
import { colors } from "../theme/theme";

export const ErrorPage = () => {
    const bgGradient = useColorModeValue(
        `linear(to-br, ${colors.pageBackgrounds.light.startGradient}, ${colors.pageBackgrounds.light.endGradient})`,
        `linear(to-br, ${colors.pageBackgrounds.dark.startGradient}, ${colors.pageBackgrounds.dark.endGradient})`
    );
    return (
        <Flex
            bgGradient={bgGradient}
            minHeight="100vh"
            position="relative"
            overflow="hidden"
            justifyContent="center"
            alignItems="center"
        >
            <Heading fontSize="4xl">
                You've experienced an error, please try again later.
            </Heading>
        </Flex>
    );
};
